import { toImmutable } from 'optly/immutable';

import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';

import PermissionsGetters from 'optly/modules/permissions/getters';

import {
  experimentApiNames,
  experimentAudiences,
  experimentHistory,
  experimentPages,
  experimentIntegrations,
  experimentMetrics,
  experimentSchedule,
  experimentSettings,
  experimentSharedCode,
  experimentTrafficAllocation,
  experimentVariations,
  experimentOverview,
} from 'optly/services/url_helper';

import CampaignManagerEnums from 'bundles/p13n/modules/campaign_manager/enums';

export const orderedVariations = [
  CurrentLayerGetters.currentSingleExperimentFromAllExperimentsPointingToLayer,
  experiment => {
    if (experiment) {
      return experiment.get('variations');
    }
    return toImmutable([]);
  },
];

/**
 * Getter for the map of sidebar items necessary for this section.
 * @type {Getter}
 */
export const sideBarItems = [
  CurrentProjectGetters.id,
  CurrentLayerGetters.currentSingleExperimentFromAllExperimentsPointingToLayer,
  PermissionsGetters.canUseLayerIntegrations,
  (projectId, experiment, canUseLayerIntegrations) => {
    const experimentId = experiment && experiment.get('id');
    return {
      variations: {
        name: CampaignManagerEnums.tabs.VARIATIONS,
        url: experimentVariations(projectId, experimentId),
      },
      pages: {
        name: CampaignManagerEnums.tabs.PAGES,
        url: experimentPages(projectId, experimentId),
      },
      audiences: {
        name: CampaignManagerEnums.tabs.AUDIENCES,
        url: experimentAudiences(projectId, experimentId),
      },
      integrations: canUseLayerIntegrations && {
        name: CampaignManagerEnums.tabs.INTEGRATIONS,
        url: experimentIntegrations(projectId, experimentId),
      },
      metrics: {
        name: CampaignManagerEnums.tabs.METRICS,
        url: experimentMetrics(projectId, experimentId),
      },
      custom_code: {
        name: CampaignManagerEnums.tabs.CUSTOM_CODE,
        url: experimentSharedCode(projectId, experimentId),
      },
      traffic_allocation: {
        name: CampaignManagerEnums.tabs.TRAFFIC_ALLOCATION,
        url: experimentTrafficAllocation(projectId, experimentId),
      },
      schedule: {
        name: CampaignManagerEnums.tabs.SCHEDULE,
        url: experimentSchedule(projectId, experimentId),
      },
      api_names: {
        name: CampaignManagerEnums.tabs.API_NAMES,
        url: experimentApiNames(projectId, experimentId),
      },
      history: {
        name: CampaignManagerEnums.tabs.HISTORY,
        url: experimentHistory(projectId, experimentId),
      },
      settings: {
        name: CampaignManagerEnums.tabs.SETTINGS,
        url: experimentSettings(projectId, experimentId),
      },
      overview: {
        name: CampaignManagerEnums.tabs.OVERVIEW,
        url: experimentOverview(projectId, experimentId),
      },
    };
  },
];

export default {
  orderedVariations,
  sideBarItems,
};
