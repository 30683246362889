import RestApi from 'optly/modules/rest_api';

import RequestUtil from 'optly/utils/request';

import PluginsGetters from 'optly/modules/entity/plugin/getters';

import flux from 'core/flux';

import SegmentTracking from 'optly/modules/segment';

import definition from './entity_definition';
import enums from './enums';
import fns from './fns';

const baseEntityActions = RestApi.createEntityActions(definition);

/**
 * Duplicates a plugin
 * @param {Plugin} plugin
 * @return {Deferred}
 */
function duplicate(plugin) {
  const options = {
    url: `/api/v1/projects/${plugin.project_id}/plugins/${plugin.plugin_id}/duplicate`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(plugin),
  };
  return RequestUtil.makeOptlyFetchRequest(options);
}

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deletePlugin = baseEntityActions.delete;

export { deletePlugin as delete };

export function fetchActiveEventPlugins(projectId) {
  return this.fetchAll({
    project_id: projectId,
    archived: false,
    plugin_type: enums.plugin_type.EVENT,
  });
}

export function onPluginCreate(plugin, currentProjectId, snippetGlobalName) {
  const pluginToSave = fns.fillDefaults(
    plugin,
    currentProjectId,
    snippetGlobalName,
  );
  return this.save(pluginToSave);
}

export function onPluginCopy(originalPlugin, pluginToSave) {
  return duplicate(pluginToSave).then(savedPlugin => {
    notifyPluginEvent(originalPlugin.toJS(), 'Template Duplicated');
    return savedPlugin;
  });
}

export function notifyPluginsEvent(pluginIds, eventName) {
  const plugins = pluginIds.map(pluginId =>
    flux.evaluateToJS(PluginsGetters.byId(parseInt(pluginId, 10))),
  );
  plugins.forEach(plugin => {
    notifyPluginEvent(plugin, eventName);
  });
}

/**
 * Sends a segment tracking event for a plugin
 * @param plugin
 * @param eventName
 */
export function notifyPluginEvent(plugin, eventName) {
  SegmentTracking.tracking.trackEvent(eventName, {
    templateName: plugin.name,
    optimizelyTemplate: plugin.optimizely_template,
  });
}

export default {
  ...baseEntityActions,
  fetchActiveEventPlugins,
  onPluginCreate,
  onPluginCopy,
  notifyPluginsEvent,
  notifyPluginEvent,
};
