import _ from 'lodash';
import moment from 'moment';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import enums from 'optly/utils/enums';
import parseQueryParams from 'optly/utils/parse_query_params';

import AdminAccountFns from 'optly/modules/admin_account/fns';
import LayerFns from 'optly/modules/entity/layer/fns';
import ProjectEnums from 'optly/modules/entity/project/enums';
import ProjectFns from 'optly/modules/entity/project/fns';

import constants from './constants';

const NYT_ACCOUNT_ID = 3013110282;
const OPTLY_ACCOUNT_ID = 5935064;
// Only campaigns created after this moment will have access to ViP:
// Wednesday, Aug 23 2016, 2:00 PM PDT (GMT-0700)
const VIP_ACCESS_CUTOFF_MOMENT = moment('2016-08-23T21:00:00Z');

const ensureFeatureDefinedOrThrow = feature => {
  if (__TEST__ || __DEV__) {
    if (_.isUndefined(feature)) {
      throw new Error('PermissionsModule Error: Feature is undefined!');
    }
  }
};

const getLimit = limit =>
  limit === -1 || limit === null ? constants.UNLIMITED_VALUE : limit;

const getChangeHistoryLimit = featureConfigs => {
  return (
    featureConfigs &&
    getLimit(featureConfigs.getIn(['change-history', 'days_limit']))
  );
};

const getNumberOfSeatsLimit = featureConfigs => {
  return (
    featureConfigs &&
    getLimit(featureConfigs.getIn(['number-of-seats', 'seats_limit']))
  );
};

const getTargetedRolloutsRulesLimit = featureConfigs => {
  return (
    featureConfigs &&
    getLimit(featureConfigs.getIn(['targeted-rollouts', 'rules_limit']))
  );
};

const getNumberOfWebProjects = featureConfigs => {
  return (
    featureConfigs &&
    getLimit(featureConfigs.getIn(['number-of-web-projects', 'number']))
  );
};

const getNumberOfFXProjects = featureConfigs => {
  return (
    featureConfigs &&
    getLimit(featureConfigs.getIn(['number-of-fx-projects', 'number']))
  );
};

/**
 * Given a blacklist, feature, and value, first check if the feature is a key in the blacklist.
 * If the feature is not a key, then the value isn't blacklisted for that feature. Next
 * check if the value is included in the blacklist for that feature.
 * @private
 * @param {Object} blacklistToCheck
 * @param {String} feature
 * @param {String} value
 * @returns {Boolean}
 */
const passesBlacklist = (blacklistToCheck, feature, value) => {
  const featureIsKeyInBlacklist = Object.prototype.hasOwnProperty.call(
    blacklistToCheck,
    feature,
  );
  return !featureIsKeyInBlacklist || !blacklistToCheck[feature].includes(value);
};

/**
 * Check if project has the capability to use a feature, given its delivery mode
 * or project type.
 * Introduced to support feature gating for Edge projects.
 * @param {String} feature from enums.Features
 * @param {Immutable.Map} Project
 * @returns {boolean}
 */
export const hasProjectCapability = (project, feature) => {
  const deliveryMode = ProjectFns.getDeliveryModeOrProjectType(project);
  if (feature === 'plugins' && deliveryMode === 'edge') {
    return isFeatureEnabled('edge_extensions');
  }
  if (feature === enums.Features.MUTEX && deliveryMode === 'edge') {
    return isFeatureEnabled('edge_mutex');
  }
  return passesBlacklist(enums.DeliveryModeBlacklist, feature, deliveryMode);
};

/**
 * @param {Immutable.Map} project
 * @param {String} feature
 * @returns {Boolean}
 */
const hasProjectPermission = (project, feature) => {
  ensureFeatureDefinedOrThrow(feature);
  try {
    return project.get('project_permissions').contains(feature);
  } catch (e) {
    return false;
  }
};

/**
 * @param {Immutable.List} accountPermissions
 * @param {String} feature
 * @returns {Boolean}
 */
const hasAccountPermission = (accountPermissions, feature) => {
  ensureFeatureDefinedOrThrow(feature);
  try {
    return accountPermissions.contains(feature);
  } catch (e) {
    return false;
  }
};

/**
 * Determine if a feature is usable based on the account permissions and project.
 * @param {String} feature from enums.Features
 * @param {Immutable.List} accountPermissions
 * @param {Immutable.Map} [project]
 * @returns {boolean}
 */
const canUseFeature = (feature, accountPermissions, project = null) => {
  const hasFeature = hasAccountPermission(accountPermissions, feature);
  if (!project) {
    return hasFeature;
  }
  return hasFeature && hasProjectCapability(project, feature);
};

// Products
export function canUsePersonalization(accountPermissions, project) {
  return canUseFeature(
    enums.Features.PERSONALIZATION,
    accountPermissions,
    project,
  );
}

export function canUseOpalCopilot(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.OPAL_COPILOT);
}

export function canUseGranularPermissions(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.GRANULAR_PERMISSIONS,
  );
}

export function canUseABTesting(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.AB_TESTING);
}

export function canUseABTestingV2(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.AB_TESTING_V2);
}

export function canViewOptimizelyX(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.VIEW_DASHBOARD_X,
  );
}

export function canUseOptimizelyX(accountPermissions) {
  return (
    hasAccountPermission(accountPermissions, enums.Features.PERSONALIZATION) ||
    hasAccountPermission(accountPermissions, enums.Features.AB_TESTING_V2) ||
    hasAccountPermission(accountPermissions, enums.Features.SERVER_SIDE_TESTING)
  );
}

export function canUseXWeb(accountPermissions) {
  return (
    hasAccountPermission(accountPermissions, enums.Features.PERSONALIZATION) ||
    hasAccountPermission(accountPermissions, enums.Features.AB_TESTING_V2)
  );
}

export function canUseEdgeProjects(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.EDGE_EXPERIMENTS,
  );
}

/**
 * Return true if the account can use multiple fullstack projects.
 * @param {Immutable.List} accountPermissions
 * @returns {Boolean}
 */
export function canUseFullStackMultipleProjects(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.FULL_STACK_MULTIPLE_PROJECTS,
  );
}

export function canUseProjects(accountPermissions) {
  return (
    this.canUseXWeb(accountPermissions) ||
    this.canUseFullStackMultipleProjects(accountPermissions)
  );
}

/**
 * Return true if the account can use Event Inspector.
 *
 * @param {Immutable.List} accountPermissions
 * @param {Immutable.Map} project
 * @param {Boolean} isInRollout
 * @returns {Boolean}
 */
export function canUseEventInspector(accountPermissions, project, isInRollout) {
  return ProjectFns.isFullStackProject(project) && isInRollout;
}

/**
 * Return true if the account can use Send Flag Decisions.
 *
 * @param {Immutable.List} accountPermissions
 * @param {Immutable.Map} project
 * @param {Boolean} isInRollout
 * @returns {Boolean}
 */
export function canUseFullStackFlagDecisions(
  accountPermissions,
  project,
  isInRollout,
) {
  return isInRollout;
}

/**
 * Return true if the account can use Recommendations.
 *
 * @param {Immutable.List} accountPermissions
 * @param {Immutable.Map} project
 * @return {Boolean}
 */
export function canUseRecommendations(accountPermissions, project) {
  return (
    hasAccountPermission(accountPermissions, enums.Features.RECOMMENDER) &&
    ProjectFns.isWebProject(project) &&
    hasProjectCapability(project, enums.Features.RECOMMENDER)
  );
}

/**
 * Return true if the account can create or edit Recommendations.
 *
 * @param {Immutable.Map} project
 * @return {Boolean}
 */
export function canManageRecommendations(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.MANAGE_RECOMMENDER_SERVICE,
  );
}

// Projects
export function canCreateProject(project, account, activeProjects) {
  return (
    hasProjectPermission(project, enums.Permissions.CREATE_PROJECT) &&
    AdminAccountFns.isUnderProjectLimit(account, activeProjects)
  );
}

export function hasCreateProjectPermission(project) {
  return hasProjectPermission(project, enums.Permissions.CREATE_PROJECT);
}

export function canEditProject(project) {
  return hasProjectPermission(project, enums.Permissions.EDIT_PROJECT);
}

export function canEditShareProjects(project) {
  return hasProjectPermission(project, enums.Permissions.EDIT_PROJECT_SHARING);
}

export function canUseProjectJS(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.PROJECT_JS);
}

export function canMigrateToFlags(project) {
  return hasProjectPermission(project, enums.Permissions.MIGRATE_TO_FLAGS);
}

// Collaborators
export function canAddCollaborator(project) {
  return hasProjectPermission(project, enums.Permissions.INVITE);
}

export function canEditCollaborator(project) {
  return hasProjectPermission(project, enums.Permissions.USER_PERMISSIONS);
}

export function canRemoveCollaboratorFromAccount(project) {
  return hasProjectPermission(project, enums.Permissions.DELETE_USER_ACCOUNTS);
}

export function canUseCollaborators(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.ADD_USER);
}

export function canViewCollaborators(project) {
  return hasProjectPermission(project, enums.Permissions.VIEW_COLLABORATORS);
}

// Experiments
export function canArchiveExperiment(project) {
  return hasProjectPermission(project, enums.Permissions.ARCHIVE_EXPERIMENT);
}

export function canCreateExperiment(project) {
  return hasProjectPermission(project, enums.Permissions.CREATE_EXPERIMENT);
}

export function canEditExperiment(project, experimentStatus) {
  if (experimentStatus === enums.ExperimentStatusType.RUNNING) {
    return hasProjectPermission(
      project,
      enums.Permissions.MODIFY_RUNNING_EXPERIMENT,
    );
  }
  return hasProjectPermission(
    project,
    enums.Permissions.MODIFY_PAUSED_EXPERIMENT,
  );
}

export function canDeleteExperiment(project) {
  return hasProjectPermission(project, enums.Permissions.DELETE_EXPERIMENT);
}

export function canPauseExperiment(project) {
  return hasProjectPermission(project, enums.Permissions.PAUSE_EXPERIMENT);
}

export function canSetImageCdnHostPrefix(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.IMAGE_CDN_HOST_PREFIX,
  );
}

export function canStartExperiment(project) {
  return hasProjectPermission(project, enums.Permissions.START_EXPERIMENT);
}

export function canUnarchiveExperiment(project) {
  return hasProjectPermission(project, enums.Permissions.ARCHIVE_EXPERIMENT);
}

export function canUseFullStackExperiments(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.FULL_STACK_EXPERIMENTS,
  );
}

// Audiences
export function canArchiveAudience(project) {
  return hasProjectPermission(project, enums.Permissions.MODIFY_AUDIENCE);
}

export function canCreateAudience(project) {
  return hasProjectPermission(project, enums.Permissions.CREATE_AUDIENCE);
}

export function canViewAudience(project) {
  return hasProjectPermission(project, enums.Permissions.VIEW_AUDIENCE);
}

/**
 * @param {Immutable.Map} project
 */
export function canEditAudience(project) {
  return hasProjectPermission(project, enums.Permissions.MODIFY_AUDIENCE);
}

// Layers
export function canCreateLayer(project) {
  return hasProjectPermission(project, enums.Permissions.CREATE_LAYER);
}

export function canDeleteLayer(project) {
  return hasProjectPermission(project, enums.Permissions.DELETE_LAYER);
}

export function canUpdateLayer(project) {
  return hasProjectPermission(project, enums.Permissions.UPDATE_LAYER);
}

export function canViewLayer(project) {
  return hasProjectPermission(project, enums.Permissions.VIEW_LAYER);
}

export function canPublishLayer(project) {
  return (
    this.canCreateCommit(project) &&
    this.canUpdateCommit(project) &&
    this.canCreateLiveCommitTag(project) &&
    this.canUpdateLiveCommitTag(project)
  );
}

export function canDeactivateLayer(project) {
  return this.canUpdateLiveCommitTag(project);
}

// Layer Experiments
export function canCreateLayerExperiment(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.CREATE_LAYER_EXPERIMENT,
  );
}

export function canDeleteLayerExperiment(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.DELETE_LAYER_EXPERIMENT,
  );
}

export const canUpdateLayerExperiment = project =>
  hasProjectPermission(project, enums.Permissions.UPDATE_LAYER_EXPERIMENT);

export function canUpdateLayerExperimentWithExtension(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.UPDATE_LAYER_EXPERIMENT_WITH_EXTENSION,
  );
}

export function canViewLayerExperiment(project) {
  return hasProjectPermission(project, enums.Permissions.VIEW_LAYER_EXPERIMENT);
}

// Commits
export function canCreateCommit(project) {
  return hasProjectPermission(project, enums.Permissions.CREATE_COMMIT);
}

export function canUpdateCommit(project) {
  return hasProjectPermission(project, enums.Permissions.UPDATE_COMMIT);
}

export function canViewCommit(project) {
  return hasProjectPermission(project, enums.Permissions.VIEW_COMMIT);
}

// CommitTags 'Live'
export function canCreateLiveCommitTag(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.CREATE_LIVE_COMMIT_TAG,
  );
}

export function canUpdateLiveCommitTag(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.UPDATE_LIVE_COMMIT_TAG,
  );
}

export function canViewLiveCommitTag(project) {
  return hasProjectPermission(project, enums.Permissions.VIEW_LIVE_COMMIT_TAG);
}

// Events
export function canCreateUserEvent(project) {
  return hasProjectPermission(project, enums.Permissions.CREATE_USER_EVENT);
}

export function canDeleteUserEvent(project) {
  return hasProjectPermission(project, enums.Permissions.DELETE_USER_EVENT);
}

export function canUpdateUserEvent(project) {
  return hasProjectPermission(project, enums.Permissions.UPDATE_USER_EVENT);
}

export function canViewUserEvent(project) {
  return hasProjectPermission(project, enums.Permissions.VIEW_USER_EVENT);
}

/*
 * Return true if the account can use full stack events.
 * @param {Immutable.List} accountPermissions
 * @returns {Boolean}
 */
export function canUseFullStackEvents(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.FULL_STACK_EVENTS,
  );
}

// Tags
export function canCreateTag(project) {
  return hasProjectPermission(project, enums.Permissions.CREATE_TAG);
}

export function canDeleteTag(project) {
  return hasProjectPermission(project, enums.Permissions.DELETE_TAG);
}

export function canUpdateTag(project) {
  return hasProjectPermission(project, enums.Permissions.UPDATE_TAG);
}

export function canViewTag(project) {
  return hasProjectPermission(project, enums.Permissions.VIEW_TAG);
}

// Views
export function canCreateView(project) {
  return hasProjectPermission(project, enums.Permissions.CREATE_VIEW);
}

export function canDeleteView(project) {
  return hasProjectPermission(project, enums.Permissions.DELETE_VIEW);
}

export function canUpdateView(project) {
  return hasProjectPermission(project, enums.Permissions.UPDATE_VIEW);
}

export function canViewView(project) {
  return hasProjectPermission(project, enums.Permissions.VIEW_VIEW);
}

// Checks if the user can use the Extensions (a.k.a. widgets, plugins) feature
export const canUsePlugins = (accountPermissions, project) =>
  canUseFeature(enums.Features.PLUGINS, accountPermissions, project);

export function canUseAnalyticsIntegrationExtensions(
  accountPermissions,
  project,
) {
  return canUseFeature(
    enums.Features.ANALYTICS_EXTENSIONS,
    accountPermissions,
    project,
  );
}

export function canUseEventExtensions(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.EVENT_EXTENSIONS,
  );
}

export function canUseEdgeProjectSettings(project) {
  const deliveryMode = ProjectFns.getDeliveryModeOrProjectType(project);
  return deliveryMode === ProjectEnums.delivery_modes.EDGE;
}

export function canUseSelectContainer(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.SELECT_CONTAINER,
  );
}

// Checks if the account allows targeting by behavioral conditions
export function canTargetBehavior(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.BEHAVIORAL_TARGETING,
  );
}

// Custom Attributes (Dimensions under the hood)
export function canCreateCustomAttribute(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.CREATE_CUSTOM_ATTRIBUTE,
  );
}

export function canDeleteCustomAttribute(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.DELETE_CUSTOM_ATTRIBUTE,
  );
}

export function canEditCustomAttribute(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.MODIFY_CUSTOM_ATTRIBUTE,
  );
}

// DCP Datasources permissions
export function canUseDatasources(accountPermissions, project) {
  return (
    ProjectFns.isWebProject(
      project,
    ) /* TODO (Jess) Check this as a capability */ &&
    canUseFeature(
      enums.Features.DYNAMIC_CUSTOMER_PROFILE,
      accountPermissions,
      project,
    )
  );
}

// Specific permission to determine if an account has access to create and link datasources
// This particularly matters on the account dashboard where the admin account is used
// to determine whether or not the DCP tab is shown.
export function canAccountUseDatasources(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.DYNAMIC_CUSTOMER_PROFILE,
  );
}

export function canUseCustomAttributes(accountPermissions, project) {
  return (
    ProjectFns.isCustomProject(project) ||
    canUseFeature(enums.Features.CUSTOM_ATTRIBUTES, accountPermissions, project)
  );
}

export function canCreateListAttributes(project) {
  return (
    ProjectFns.isWebProject(project) &&
    hasProjectPermission(project, enums.Permissions.MANAGE_USER_LIST)
  );
}

export function canEditListAttributes(project) {
  return (
    ProjectFns.isWebProject(project) &&
    hasProjectPermission(project, enums.Permissions.MANAGE_USER_LIST)
  );
}

export function canUseListAttributes(accountPermissions, project) {
  return (
    ProjectFns.isWebProject(project) &&
    canUseFeature(enums.Features.LIST_ATTRIBUTE, accountPermissions, project)
  );
}

// Integrations
export function canEditIntegrationForProject(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.EDIT_PROJECT_INTEGRATION,
  );
}

export function canUseIntegration(accountPermissions, requiredPermission) {
  return hasAccountPermission(accountPermissions, requiredPermission);
}

// Auto traffic allocation feature
export function canUseScheduler(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.SCHEDULING);
}

// Mutual Exclusivity in A/B 1.0 product for custom projects
export function canCreateGroup(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.CREATE_EXPERIMENT_GROUP,
  );
}

export function canUseGroups(project) {
  // @TODO(mng): replace/remove references to this method once we launch MUTEX for custom projects
  return _.includes(project.project_platforms, enums.ProjectPlatforms.CUSTOM);
}

// Groups
export function canArchiveGroup(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.DELETE_EXPERIMENT_GROUP,
  );
}

export function canEditGroup(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.UPDATE_EXPERIMENT_GROUP,
  );
}

// Checks if the account allows exporting of event logs.
export function canExportEvents(project) {
  return hasProjectPermission(project, enums.Permissions.EXPORT_EVENTS);
}

export function canUseCustomSegments(accountPermissions, project) {
  return canUseFeature(
    enums.Features.CUSTOM_SEGMENTS,
    accountPermissions,
    project,
  );
}

/**
 * Checks if users have access to the new Datafile Authentication UI.
 *
 * @param {Immutable.List} accountPermissions
 * @param {Immutable.Map} project
 * @param {Boolean} isInRollout is user in datafile_authentication feature flag?
 * @returns {Boolean}
 */
export function canUseDatafileAuthentication(
  accountPermissions,
  project,
  isInRollout,
) {
  return (
    isInRollout &&
    hasAccountPermission(accountPermissions, enums.Features.SECURE_ENVIRONMENTS)
  );
}

export function canEnableDatafileAuthentication(isInRollout) {
  return isInRollout;
}

export function canModifyAccountTokens(project) {
  return hasProjectPermission(project, enums.Permissions.MODIFY_ACCOUNT_TOKENS);
}

export function canShowBilling(project) {
  return hasProjectPermission(project, enums.Permissions.BILLING);
}

export function canGenerateToken(project) {
  return hasProjectPermission(project, enums.Permissions.MODIFY_ACCOUNT_TOKENS);
}

// Check if the user's account is enabled to view the web editor drawer
export function canUseWebEditorDrawer(project) {
  return hasProjectPermission(project, enums.Permissions.VUE_EDITOR_DRAWER);
}

export function canDebugEditor(project) {
  return hasProjectPermission(project, enums.Permissions.EDITOR_DEBUG);
}

export function canManageBearerToken(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Permissions.MANAGE_BEARER_TOKEN,
  );
}

export function canDuplicateAcrossProjects(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.CROSS_PROJECT_DUPLICATION,
  );
}

export function hasRestAPIAccess(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.REST_API);
}

/**
 * Checks if the user / account can register, modify, or delete oauth clients.
 * @param accountPermissions permission flags from the current account / user, usually obtained from AdminAccount.getters.accountPermissions
 * @returns {boolean}
 */
export function canManageOAuthClient(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Permissions.MANAGE_OAUTH_CLIENT,
  );
}

export function canViewOAuthClient(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Permissions.VIEW_OAUTH_CLIENT,
  );
}

export function canEditAccount(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Permissions.EDIT_PROJECT,
  );
}

export function canShowAccountBilling(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Permissions.BILLING);
}

export function canEnableAccountTwoFactorAuth(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.ACCOUNT_TWO_FACTOR_AUTH,
  );
}

export function canEnableUserTwoFactorAuth(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.USER_TWO_FACTOR_AUTH,
  );
}

export function canCreateSubjectAccessRequest(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Permissions.CREATE_SAR);
}

export function canReadSubjectAccessRequest(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Permissions.READ_SAR);
}

/**
 * Checks if the user / account can use the Adaptive Audiences feature. See go/ada for the TDD.
 * @param accountPermissions permission flags from the current account / user, usually obtained from AdminAccount.getters.accountPermissions
 * @param project current Project
 * @returns {boolean}
 */
export const canUseAdaptiveAudiences = (accountPermissions, project) => {
  const hasFeature = hasAccountPermission(
    accountPermissions,
    enums.Features.ADAPTIVE_AUDIENCES,
  );
  const hasCapability =
    ProjectFns.isWebProject(project) &&
    hasProjectCapability(project, enums.Features.ADAPTIVE_AUDIENCES);
  return hasFeature && hasCapability;
};

/**
 * Checks if the account & project can use the Multi Armed Bandits feature.
 * @returns {boolean}
 */
export const canUseMultiArmedBandits = (accountPermissions, project) => {
  const hasFeature = hasAccountPermission(
    accountPermissions,
    enums.Features.BANDITS_V2,
  );
  const hasCapability = hasProjectCapability(
    project,
    enums.Features.BANDITS_V2,
  );
  return hasFeature && hasCapability;
};

/**
 * Checks if the account & project can use the Contextual Multi Armed Bandits (CMAB) feature.
 *
 * @param {Immutable.List} accountPermissions - Permission flags from the current account/user, usually obtained from AdminAccount.getters.accountPermissions.
 * @param {Immutable.Map} project - The current project.
 * @returns {boolean} - Returns true if the project has the CMAB capability and the account has the necessary permissions, otherwise false.
 */
export const canUseContextualMultiArmedBandits = (
  accountPermissions,
  project,
) => {
  /**
   * The @accountPermissions and @project are not used in the current implementation.
   * This is because the CMAB feature is not gated by account permissions or project capabilities.
   * The feature flag is checked directly.
   */
  return isFeatureEnabled('web-cmab-flag');
};

/**
 * Checks if the account & project can use the Stats Accelerator feature.
 * @returns {boolean}
 */
export const canUseStatsAccelerator = (accountPermissions, project) => {
  const hasFeature = hasAccountPermission(
    accountPermissions,
    enums.Features.STATS_ACCELERATOR,
  );
  const hasCapability = hasProjectCapability(
    project,
    enums.Features.STATS_ACCELERATOR,
  );
  return hasFeature && hasCapability;
};

/**
 /**
 * Checks if the user / account can subscribe/unsubscribe to entities using the Concurrency module.
 * @param accountPermissions permission flags from the current account / user, usually obtained from AdminAccount.getters.accountPermissions
 * @returns {boolean}
 */
export const canUseConcurrentEditing = accountPermissions =>
  hasAccountPermission(accountPermissions, enums.Features.CONCURRENT_EDITING);
/**
 * Checks if the user / account can use the feature that allows for indefinite observation of editor DOM changes via mutation observers.
 * @param accountPermissions permission flags from the current account / user, usually obtained from AdminAccount.getters.accountPermissions
 * @returns {boolean}
 */
export const canUseObserveChangesIndefinitely = accountPermissions =>
  hasAccountPermission(
    accountPermissions,
    enums.Features.OBSERVE_CHANGES_INDEFINITELY,
  );

// Snippets
export const canCreateSnippet = project =>
  hasProjectPermission(project, enums.Permissions.CREATE_SNIPPET);
export const canDeleteSnippet = project =>
  hasProjectPermission(project, enums.Permissions.DELETE_SNIPPET);
export const canUpdateSnippet = project =>
  hasProjectPermission(project, enums.Permissions.UPDATE_SNIPPET);
export const canUseSnippets = accountPermissions =>
  hasAccountPermission(accountPermissions, enums.Features.SNIPPETS);
export const canViewSnippet = project =>
  hasProjectPermission(project, enums.Permissions.VIEW_SNIPPET);
// Checks if the user / account can use Traffic automation feature
export const canUseShareProjects = (accountPermissions, project) =>
  canUseFeature(enums.Features.SHAREABLE_PROJECTS, accountPermissions, project);

/**
 * Checks if the user/account can use Server Side Testing
 * @param accountPermissions permission flags from the current account/user, usually obtained from AdminAccount.getters.accountPermissions
 * @returns {boolean}
 */
export function canUseServerSideTesting(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.SERVER_SIDE_TESTING,
  );
}

/**
 * Checks if the user can access Users tab in account settings
 * @param {Array} accountPermissions
 * @returns {boolean}
 */
export const canViewUsersTab = accountPermissions =>
  hasAccountPermission(
    accountPermissions,
    enums.Permissions.VIEW_USER_ACCOUNTS,
  );

/**
 * Checks if the user can delete other users from the account
 * @param {Array} accountPermissions
 * @returns {boolean}
 */
export const canDeleteUser = accountPermissions =>
  hasAccountPermission(
    accountPermissions,
    enums.Permissions.DELETE_USER_ACCOUNTS,
  );
/**
 * Checks if the user can update other users from the account
 * @param {Array} accountPermissions
 * @returns {boolean}
 */
export const canUpdateUser = accountPermissions =>
  hasAccountPermission(
    accountPermissions,
    enums.Permissions.UPDATE_USER_ACCOUNTS,
  );

/**
 * Checks if user/account can use Mobile Testing SDKs
 * @param {Array} accountPermissions
 */
export function canUseMobileSDKs(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.MOBILE);
}

/**
 * Checks if user/account can use the Full Stack Testing SDKs
 * @param {Array} accountPermissions
 */
export function canUseFullStackSDKs(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.FULL_STACK);
}

/**
 * Checks if user/account can use the Over-the-Top Testing SDKs
 * @param {Array} accountPermissions
 */
export function canUseOverTheTopSDKs(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.OTT);
}

export function canUseOasis() {
  return false;
}

/**
 * Special helper permission function indicating that New York Times special features should be visible.
 * Right now this is just the NYT or Optimizely account ids, plus __DEV__ and __TEST__
 */
export function canSeeNewYorkTimesFeatures(adminAccountId) {
  return (
    _.includes([NYT_ACCOUNT_ID, OPTLY_ACCOUNT_ID], adminAccountId) ||
    (__DEV__ &&
      parseQueryParams(window.location.search).dev_force_nyt === 'true')
  );
}

// Webhooks
export function canManageWebhooks(project) {
  return hasProjectPermission(project, enums.Permissions.MANAGE_WEBHOOK);
}

/**
 * @param {Immutable.Map} project
 * @param {Immutable.Map} layer
 * @return {Boolean}
 */
export function canViewVariationsInP13N(project, layer) {
  if (!layer) {
    return false;
  }

  if (LayerFns.isABTestLayer(layer.toJS())) {
    return false;
  }

  if (
    hasProjectPermission(
      project,
      enums.Features.CONTINUOUS_OPTIMIZATION_DASHBOARD,
    )
  ) {
    return true;
  }

  const layerCreatedStr = layer.get('created');
  if (!_.isString(layerCreatedStr)) {
    return false;
  }
  const layerCreationMoment = moment(layerCreatedStr);
  if (!layerCreationMoment.isValid()) {
    return false;
  }

  return VIP_ACCESS_CUTOFF_MOMENT.isBefore(layerCreationMoment);
}

export function canChangeSnippetBundlingConfiguration(project) {
  return hasProjectPermission(
    project,
    enums.Permissions.SNIPPET_BUNDLING_CONFIGURATION,
  );
}

export function canCreateShareTokens(project) {
  return hasProjectPermission(project, enums.Permissions.MODIFY_SHARE_TOKENS);
}

export function canViewShareTokens(project) {
  return hasProjectPermission(project, enums.Permissions.VIEW_SHARE_TOKENS);
}

export function canUseCrossProjectMetrics(accountPermissions, project) {
  return canUseFeature(
    enums.Features.CROSS_PROJECT_METRICS,
    accountPermissions,
    project,
  );
}

// Live Variables
export function canArchiveVariable() {
  // TODO(delikat): implement
  return true;
}

export function canCreateVariable() {
  // TODO(delikat): implement
  return true;
}

export function canEditVariable() {
  // TODO(delikat): implement
  return true;
}

export function canManageFeatureFlags(project) {
  return hasProjectPermission(project, enums.Permissions.MANAGE_FEATURE_FLAG);
}

export const shouldDisableEditorIframePreload = accountPermissions =>
  canUseFeature(
    enums.Features.DISABLE_EDITOR_IFRAME_PRELOAD,
    accountPermissions,
  );

/**
 * Checks if the user / account can launch experiments.
 * @param accountPermissions permission flags from the current account / user, usually obtained from AdminAccount.getters.accountPermissions
 * @returns {boolean}
 */
export function canLaunchExperiment(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.LAUNCH_EXPERIMENT,
  );
}

/**
 * Checks if the user / account can use Mutual Exclusivity
 * @param accountPermissions permission flags from the current account / user, usually obtained from AdminAccount.getters.accountPermissions
 * @param project current project
 * @returns {boolean}
 */
export const canUseMutex = (accountPermissions, project) =>
  canUseFeature(enums.Features.MUTEX, accountPermissions, project);

/**
 * Checks if the user / account can use MVT in X
 * @param accountPermissions permission flags from the current account / user, usually obtained from AdminAccount.getters.accountPermissions
 * @param project
 * @returns {boolean}
 */
export function canMultivariateTestInX(accountPermissions, project) {
  return canUseFeature(enums.Features.MVT_X, accountPermissions, project);
}

/**
 * Checks if the user / account can use MVT in X in Full Stack
 * @param accountPermissions permission flags from the current account / user, usually obtained from AdminAccount.getters.accountPermissions
 * @returns {boolean}
 */
export function canMultivariateTestInXFullStack(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.MVT_X_FULLSTACK,
  );
}

/**
 * Checks if the user / account can use Feature Flags
 */
export function canUseFeatureFlags(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.FEATURE_FLAGS);
}

/**
 * Checks if the user / account can use Feature Rollouts
 */
export function canUseFeatureRollouts(accountPermissions) {
  return (
    hasAccountPermission(accountPermissions, enums.Features.FEATURE_FLAGS) &&
    hasAccountPermission(accountPermissions, enums.Features.FEATURE_ROLLOUTS)
  );
}

/**
 * Checks if the account + project can use Targeted Rollouts
 */
export function canCurrentProjectUseTargetedRollouts(
  accountPermissions,
  isInTargetedRollouts,
) {
  return (
    isInTargetedRollouts ||
    hasAccountPermission(accountPermissions, enums.Features.TARGETED_ROLLOUTS)
  );
}

export function canUseUrlTargeting(accountPermissions) {
  return hasAccountPermission(accountPermissions, enums.Features.URL_TARGETING);
}

// Access to Product Management
export function canUseTeamsWorkflow(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.ENABLE_TEAMS_WORKFLOW,
  );
}

/**
 * Returns true if the account's permissions contain PUBLISH_LAYER_ONLY.
 *
 * // TODO: Implement the following
 * This feature changes the appearance and behavior of the publish button in
 * the p13n campaign overview page. When this feature is enabled and the
 * campaign has been published before, the button says'Publish Campaign
 * Settings', and clicking it will publish any changes to the campgaign, but
 * not any changes to experiences in the campaign.
 *
 * See: https://optimizely.atlassian.net/browse/WEB-1485
 *
 * @param {Immutable.List} accountPermissions
 * @returns {boolean}
 */
export function shouldPublishLayerOnly(accountPermissions) {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.PUBLISH_LAYER_ONLY,
  );
}

/**
 * Return true if the project's permissions contain RESET_RESULTS.
 *
 * @param {Immutable.Map} project
 * @return {Boolean}
 */
export const canResetResults = project =>
  hasProjectPermission(project, enums.Permissions.RESET_RESULTS);

/**
 * Return true if the accounts permissions contain OUTLIER_FILTERING or if they include OUTLIER_FILTERING_GA and
 * the layer in question has never been started or was started after the cutoff date.
 *
 * @param {Immutable.List} accountPermissions
 * @param {Immutable.Map} layer
 * @return {Boolean}
 */
export const canUseOutlierFiltering = (accountPermissions, layer) => {
  const hasLegacyPermission = hasAccountPermission(
    accountPermissions,
    enums.Features.OUTLIER_FILTERING,
  );
  const hasGAPermission = hasAccountPermission(
    accountPermissions,
    enums.Features.OUTLIER_FILTERING_GA,
  );

  if (!layer) {
    return hasGAPermission || hasLegacyPermission;
  }

  const earliestDate =
    (layer.get('earliest') && moment(layer.get('earliest'))) || moment();

  const createdOrStartedBeforeCutoff = earliestDate.isBefore(
    constants.OUTLIER_FILTERING_CUTOFF_DATE,
  );

  return (
    LayerFns.isABTestLayer(layer) &&
    (hasLegacyPermission || (hasGAPermission && !createdOrStartedBeforeCutoff))
  );
};

/**
 * Return true if the accounts permissions contain ENVIRONMENTS.
 * ENVIRONMENTS BETA - remove this option and the conditional when we have general-availability
 *
 * @param {Immutable.Map} accountPermissions
 * @return {Boolean}
 */
export const canUseEnvironments = accountPermissions =>
  hasAccountPermission(accountPermissions, enums.Features.ENVIRONMENTS);

/**
 * Return true if the project permissions contains MVT_X_PARTIAL_FACTORIAL
 * @param {Immutable.Map} project
 * @returns {boolean}
 * JIRA: https://optimizely.atlassian.net/browse/APP-1846
 */
export const canUsePartialFactorial = (accountPermissions, project) =>
  canUseFeature(
    enums.Features.MVT_X_PARTIAL_FACTORIAL,
    accountPermissions,
    project,
  );

/**
 * Return true if the project permissions contain MODIFY_RUNNING_EXPERIMENT.
 *
 * @param {Immutable.Map} project
 * @return {Boolean}
 */
export const canModifyRunningExperiment = project =>
  hasProjectPermission(project, enums.Permissions.MODIFY_RUNNING_EXPERIMENT);

/**
 * Return true if the project permissions contain MANAGE_MANIFEST.
 *
 * @param {Immutable.Map} project
 * @return {Boolean}
 */
export const canManageManifest = project =>
  hasProjectPermission(project, enums.Permissions.MANAGE_MANIFEST);

/**
 * Return true if the accounts permissions contain FEATURE_MANAGEMENT.
 * FEATURE MANAGEMENT 2.0 BETA - remove this option and the conditional when we have general-availability
 *
 * @param {Immutable.Map} accountPermissions
 * @return {Boolean}
 */
export const canUseFeatureManagement = accountPermissions =>
  hasAccountPermission(accountPermissions, enums.Features.FEATURE_MANAGEMENT);

/**
 * Return true if the user can use feature tests in the given project.
 * FEATURE_MANAGEMENT *must* be in their account permissions. They don't need to have full-stack-experiments.
 * @param {Immutable.List} accountPermissions
 * @param {Boolean} isFullStackProject - Is it a full stack project, i.e. non mobile
 * @returns {Boolean}
 */
export const canUseFeatureTests = (accountPermissions, isFullStackProject) => {
  if (!isFullStackProject) {
    return false;
  }
  return canUseFeatureManagement(accountPermissions);
};

/**
 * Return true if the accounts permissions contain FULL_STACK_BOT_FILTERING.
 * @TODO(ali): Remove when bot filtering is released for all SDKs.
 *
 * @param {Immutable.Map} accountPermissions
 * @return {Boolean}
 */
export const canUseFullStackBotFiltering = accountPermissions =>
  hasAccountPermission(
    accountPermissions,
    enums.Features.FULL_STACK_BOT_FILTERING,
  );

/**
 * Return true if the account can use mobile SDKs, but not full stack SDKs.
 * @param {Immutable.List} accountPermissions
 * @returns {Boolean}
 */
export const isMobileOnlyAccount = accountPermissions =>
  hasAccountPermission(accountPermissions, enums.Features.MOBILE) &&
  !hasAccountPermission(accountPermissions, enums.Features.FULL_STACK) &&
  !hasAccountPermission(accountPermissions, enums.Features.AB_TESTING_V2) &&
  !hasAccountPermission(accountPermissions, enums.Features.OTT);

/**
 * Return true if the account can use custom audience integrations.
 * @param {Immutable.List} accountPermissions
 * @returns {Boolean}
 */
export const canUseCustomAudienceIntegrations = accountPermissions =>
  hasAccountPermission(
    accountPermissions,
    enums.Features.CUSTOM_AUDIENCE_INTEGRATIONS,
  );

/**
 * Return true if the user role permits the user to create or delete links between Optimizely entities and Jira entities
 * @param {Immutable.Map} project
 * @returns {Boolean}
 */
export const canUpdateJiraLinks = project =>
  hasProjectPermission(project, enums.Permissions.UPDATE_ATLASSIAN_LINKS);

export const canUseAsyncChanges = project =>
  hasProjectCapability(project, enums.Features.ASYNC_CHANGES);
export const canUseChangeDependencies = project =>
  hasProjectCapability(project, enums.Features.CHANGE_DEPENDENCIES);

/*
 * Return true if the account can use full feature variables.
 * @param {Immutable.List} accountPermissions
 * @returns {Boolean}
 */
export const canUseFeatureVariables = accountPermissions =>
  hasAccountPermission(
    accountPermissions,
    enums.Features.FEATURE_MANAGEMENT_VARIABLES,
  );

/*
 * Return true if the account can access advanced settings for a fullstack project
 * @param {Immutable.List} accountPermissions
 * @returns {Boolean}
 */
export const canUseFullStackAdvancedSettings = accountPermissions => {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.FULL_STACK_ADVANCED_SETTINGS,
  );
};

/*
 * Return true if the account can access advanced settings for a fullstack project
 * @param {Immutable.List} accountPermissions
 * @returns {Boolean}
 */
export const canUseStatSigNotificationPreference = accountPermissions => {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.STAT_SIG_NOTIFICATION_PREFERENCE,
  );
};

/**
 * Return true if user can use advanced page settings based on
 * the current project delivery mode or type.
 * @param project
 * @returns {boolean}
 */
export const canUseAdvancedPageSettings = project =>
  hasProjectCapability(project, enums.Features.ADVANCED_PAGE_SETTINGS);

/**
 * Return true if user can use the "callback" view activaiton mode based on
 * the current project delivery mode or type.
 * @param project
 * @returns {boolean}
 */
export const canUseViewActivationModeCallback = project =>
  hasProjectCapability(project, enums.Features.ACTIVATION_MODE_CALLBACK);

/**
 * Return true if user can use the "dom changed" view activation mode based on
 * the current project delivery mode or type.
 * @param project
 * @returns {boolean}
 */
export const canUseViewActivationModeDomChanged = project =>
  hasProjectCapability(project, enums.Features.ACTIVATION_MODE_DOM_CHANGED);

/**
 * Return true if user can use the "manual" view activation mode based on
 * the current project delivery mode or type.
 * @param project
 * @returns {boolean}
 */
export const canUseViewActivationModeManual = project =>
  hasProjectCapability(project, enums.Features.ACTIVATION_MODE_MANUAL);

/**
 * Return true if user can use the "polling" view activation mode based on
 * the current project delivery mode or type.
 * @param project
 * @returns {boolean}
 */
export const canUseViewActivationModePolling = project =>
  hasProjectCapability(project, enums.Features.ACTIVATION_MODE_POLLING);

/**
 * Return true if user can use the "url changed" view activation mode based on
 * the current project delivery mode or type.
 * @param project
 * @returns {boolean}
 */
export const canUseViewActivationModeUrlChanged = project =>
  hasProjectCapability(project, enums.Features.ACTIVATION_MODE_URL_CHANGED);

/**
 * Return true if user can use the "custom code" view condition based on
 * the current project delivery mode or type.
 * @param project
 * @returns {boolean}
 */
export const canUseViewConditionCustomCode = project =>
  hasProjectCapability(project, enums.Features.VIEW_CONDITION_CUSTOM_CODE);

/**
 * Return true if user can use the "element present" view condition based on their accountPermissions (account features)
 * and the current project delivery mode or type.
 * @param project
 * @returns {boolean}
 */
export const canUseViewConditionElementPresent = project =>
  hasProjectCapability(project, enums.Features.VIEW_CONDITION_ELEMENT_PRESENT);

export const shouldUpsellPersonalization = project =>
  hasProjectCapability(project, enums.Features.PERSONALIZATION);

export const canUseVisualTags = project =>
  hasProjectCapability(project, enums.Features.VISUAL_TAGS);

export const canUsePreview = project =>
  hasProjectCapability(project, enums.Features.PREVIEW);

export const canUseLayerIntegrations = project =>
  hasProjectCapability(project, enums.Features.LAYER_INTEGRATIONS);

export const canUseWebStickyBucketing = project =>
  hasProjectCapability(project, enums.Features.WEB_STICKY_BUCKETING);

/**
 * Return true if the account can file online support tickets.
 *
 * @param {Immutable.List} accountPermissions
 * @return {Boolean}
 */

export const canUseOnlineTickets = accountPermissions =>
  hasAccountPermission(accountPermissions, enums.Features.ONLINE_TICKETS);

/**
 * Returns true if the account can use any of layer table row actions.
 *
 * @param {Immutable.List} accountPermissions
 * @param {Boolean} isInRollout
 * @returns {Boolean}
 */
export function canUseLayerTableRowActions(accountPermissions, project) {
  return (
    this.canPublishLayer(project) ||
    this.canDeactivateLayer(project) ||
    this.canDeleteLayer(project) ||
    this.canDuplicateAcrossProjects(accountPermissions) ||
    this.canCreateExperiment(project)
  );
}

/**
 * Checks if the account can use the AI Web Copy Variations feature.
 * it verifies if the Feature Flag and the Feature Gate are enabled
 * @returns {boolean}
 */
export const canUseAIWebCopyVariations = accountPermissions =>
  isFeatureEnabled('ai_web_copy_variations') &&
  hasAccountPermission(
    accountPermissions,
    enums.Features.AI_WEB_COPY_VARIATIONS,
  );

/**
 * Checks if the account can use the AI Variation Summary feature.
 * Returns true if both the Feature Flag 'ai_variation_summary_fg' and
 * Feature Gate 'ai_variation_summary' are enabled.
 * @returns {boolean}
 */
export const canUseAIVariationSummary = accountPermissions =>
  isFeatureEnabled('ai_variation_summary') &&
  hasAccountPermission(
    accountPermissions,
    enums.Features.AI_VARIATION_SUMMARY,
  );

/**
 * Return true if the account can access Change History Webhooks
 * @param {Immutable.List} accountPermissions
 * @returns {Boolean}
 */
export const canUseFXChangeHistoryWebhooks = accountPermissions => {
  return hasAccountPermission(
    accountPermissions,
    enums.Features.FX_CHANGE_HISTORY_WEBHOOKS,
  );
};

/**
 * Return true if the project permissions contain CREATE_METRICS.
 *
 * @param {Immutable.Map} project
 * @return {Boolean}
 */
export const canCreateMetrics = project =>
  hasProjectPermission(project, enums.Permissions.CREATE_METRICS);

/**
 * Return true if the project permissions contain UPDATE_METRICS.
 *
 * @param {Immutable.Map} project
 * @return {Boolean}
 */
export const canUpdateMetrics = project =>
  hasProjectPermission(project, enums.Permissions.UPDATE_METRICS);

/**
 * Return true if the project permissions contain DELETE_METRICS.
 *
 * @param {Immutable.Map} project
 * @return {Boolean}
 */

export const canDeleteMetrics = project =>
  hasProjectPermission(project, enums.Permissions.DELETE_METRICS);

/**
 * Return true if the project permissions contain CREATE_CROSS_PROJECT_METRICS.
 *
 * @param {Immutable.Map} project
 * @return {Boolean}
 */
export const canCreateCrossProjectMetrics = project =>
  hasProjectPermission(project, enums.Permissions.CREATE_CROSS_PROJECT_METRICS);

/**
 * Return true if the project permissions contain UPDATE_CROSS_PROJECT_METRICS.
 *
 * @param {Immutable.Map} project
 * @return {Boolean}
 */
export const canUpdateCrossProjectMetrics = project =>
  hasProjectPermission(project, enums.Permissions.UPDATE_CROSS_PROJECT_METRICS);

/**
 * Return true if the project permissions contain DELETE_CROSS_PROJECT_METRICS.
 *
 * @param {Immutable.Map} project
 * @return {Boolean}
 */
export const canDeleteCrossProjectMetrics = project =>
  hasProjectPermission(project, enums.Permissions.DELETE_CROSS_PROJECT_METRICS);

/**
 * Checks if the account & project can use the FX-change approvals feature.
 *
 * @param {Immutable.List} accountPermissions - Permission flags from the current account/user, usually obtained from AdminAccount.getters.accountPermissions.
 * @param {Immutable.Map} project - The current project.
 * @returns {boolean} - Returns true if the project has the FX change approvals capability.
 */
export const canUseFXChangeApprovals = (accountPermissions, project) => {
  /**
   * The @accountPermissions and @project are not used in the current implementation.
   * This is because the FX change approvals feature is not gated by account permissions or project capabilities.
   * The feature flag is checked directly.
   */
  return isFeatureEnabled('fx_change_approvals');
};

/**
 * Returns true if the account can use any type of experiments
 * @param accountPermissions
 * @param project
 * @returns {Boolean|boolean}
 */
export function canUseExperiments(accountPermissions, project) {
  return (
    canUseABTestingV2(accountPermissions) ||
    canMultivariateTestInX(accountPermissions, project) ||
    canUseMultiArmedBandits(accountPermissions, project)
  );
}

export default {
  ...(__TEST__ && { passesBlacklist }),

  ...(__TEST__ && { hasProjectCapability }),

  ...(__TEST__ && { hasProjectPermission }),

  ...(__TEST__ && { hasAccountPermission }),

  ...(__TEST__ && { canUseFeature }),

  ...(__TEST__ && { ensureFeatureDefinedOrThrow }),

  getChangeHistoryLimit,
  getNumberOfSeatsLimit,
  getTargetedRolloutsRulesLimit,

  canAccountUseDatasources,

  canUsePersonalization,
  canUseExperiments,
  canUseABTesting,
  canUseABTestingV2,
  canViewOptimizelyX,
  canUseOptimizelyX,
  canUseXWeb,
  canUseEdgeProjects,
  canUseFullStackMultipleProjects,
  canUseGranularPermissions,
  canUseLayerTableRowActions,
  canUseProjects,
  canUseEventInspector,
  canUseFullStackFlagDecisions,
  canUseRecommendations,
  canManageRecommendations,
  canCreateProject,
  hasCreateProjectPermission,
  canEditProject,
  canEditShareProjects,
  canUseProjectJS,
  canMigrateToFlags,
  canAddCollaborator,
  canEditCollaborator,
  canRemoveCollaboratorFromAccount,
  canUseCollaborators,
  canViewCollaborators,
  canArchiveExperiment,
  canCreateExperiment,
  canEditExperiment,
  canDeleteExperiment,
  canPauseExperiment,
  canSetImageCdnHostPrefix,
  canStartExperiment,
  canUnarchiveExperiment,
  canUseFullStackExperiments,
  canUseFeatureTests,
  canArchiveAudience,
  canCreateAudience,
  canViewAudience,
  canEditAudience,
  canCreateLayer,
  canDeleteLayer,
  canUpdateLayer,
  canViewLayer,
  canPublishLayer,
  canDeactivateLayer,
  canCreateLayerExperiment,
  canDeleteLayerExperiment,
  canUpdateLayerExperiment,
  canUpdateLayerExperimentWithExtension,
  canViewLayerExperiment,
  canCreateCommit,
  canUpdateCommit,
  canViewCommit,
  canCreateLiveCommitTag,
  canUpdateLiveCommitTag,
  canViewLiveCommitTag,
  canCreateUserEvent,
  canDeleteUserEvent,
  canUpdateUserEvent,
  canViewUserEvent,
  canUseFullStackEvents,
  canCreateTag,
  canDeleteTag,
  canUpdateTag,
  canViewTag,
  canCreateView,
  canDeleteView,
  canUpdateView,
  canViewView,
  canUsePlugins,
  canUseAnalyticsIntegrationExtensions,
  canUseEventExtensions,
  canUseFXChangeApprovals,
  canUseEdgeProjectSettings,
  canUseSelectContainer,
  canTargetBehavior,
  canCreateCustomAttribute,
  canDeleteCustomAttribute,
  canEditCustomAttribute,
  canUseDatasources,
  canUseCustomAttributes,
  canCreateListAttributes,
  canEditListAttributes,
  canUseListAttributes,
  canEditIntegrationForProject,
  canUseIntegration,
  canUseScheduler,
  canCreateGroup,
  canUseGroups,
  canArchiveGroup,
  canEditGroup,
  canExportEvents,
  canUseCustomSegments,
  canEnableDatafileAuthentication,
  canUseDatafileAuthentication,
  canModifyAccountTokens,
  canShowBilling,
  canGenerateToken,
  canUseWebEditorDrawer,
  canDebugEditor,
  canManageBearerToken,
  canDuplicateAcrossProjects,
  hasRestAPIAccess,
  canManageOAuthClient,
  canViewOAuthClient,
  canEditAccount,
  canShowAccountBilling,
  canEnableAccountTwoFactorAuth,
  canEnableUserTwoFactorAuth,
  canCreateSubjectAccessRequest,
  canReadSubjectAccessRequest,
  canUseAdaptiveAudiences,
  canUseConcurrentEditing,
  canUseObserveChangesIndefinitely,
  canUseSnippets,
  canCreateSnippet,
  canViewSnippet,
  canUpdateSnippet,
  canDeleteSnippet,
  canUseShareProjects,
  canUseAsyncChanges,
  canUseChangeDependencies,
  canUseServerSideTesting,
  canViewUsersTab,
  canUpdateUser,
  canDeleteUser,
  canUseMobileSDKs,
  canUseFullStackSDKs,
  canUseOverTheTopSDKs,
  canUseOasis,
  canSeeNewYorkTimesFeatures,
  canManageWebhooks,
  canViewVariationsInP13N,
  canChangeSnippetBundlingConfiguration,
  canCreateShareTokens,
  canViewShareTokens,
  canUseCrossProjectMetrics,
  canArchiveVariable,
  canCreateVariable,
  canEditVariable,
  canManageFeatureFlags,
  shouldDisableEditorIframePreload,
  canLaunchExperiment,
  canUseMultiArmedBandits,
  canUseContextualMultiArmedBandits,
  canUseStatsAccelerator,
  canUseMutex,
  canMultivariateTestInX,
  canMultivariateTestInXFullStack,
  canUseFeatureFlags,
  canUseFeatureRollouts,
  canCurrentProjectUseTargetedRollouts,
  canUseUrlTargeting,
  canUseTeamsWorkflow,
  shouldPublishLayerOnly,
  canResetResults,
  canUseOutlierFiltering,
  canUseEnvironments,
  canUsePartialFactorial,
  canModifyRunningExperiment,
  canManageManifest,
  canUseFeatureManagement,
  canUseFullStackBotFiltering,
  isMobileOnlyAccount,
  canUseCustomAudienceIntegrations,
  canUpdateJiraLinks,
  canUseFeatureVariables,
  canUseFullStackAdvancedSettings,
  canUseStatSigNotificationPreference,
  canUseAdvancedPageSettings,
  canUseOpalCopilot,
  canUseViewActivationModeCallback,
  canUseViewActivationModeDomChanged,
  canUseViewActivationModeManual,
  canUseViewActivationModePolling,
  canUseViewActivationModeUrlChanged,
  canUseViewConditionCustomCode,
  canUseViewConditionElementPresent,
  canUseVisualTags,
  canUsePreview,
  canUseLayerIntegrations,
  canUseWebStickyBucketing,
  canUseOnlineTickets,
  shouldUpsellPersonalization,
  canUseAIWebCopyVariations,
  canUseAIVariationSummary,
  getNumberOfWebProjects,
  getNumberOfFXProjects,
  canUseFXChangeHistoryWebhooks,
  canCreateMetrics,
  canUpdateMetrics,
  canDeleteMetrics,
  canCreateCrossProjectMetrics,
  canUpdateCrossProjectMetrics,
  canDeleteCrossProjectMetrics,
};
