import { toImmutable } from 'optly/immutable';
import _ from 'lodash';

import { getters as EditorGetters } from 'bundles/p13n/modules/editor';
import {
  fns as CurrentLayerFns,
  getters as CurrentLayerGetters,
} from 'bundles/p13n/modules/current_layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as ExperimentSectionGetters } from 'optly/modules/entity/experiment_section';
import LayerExperiment from 'optly/modules/entity/layer_experiment';
import { fns as PermissionsModuleFns } from 'optly/modules/permissions';

import {
  mvtSectionsDashboard,
  mvtIntegrations,
  mvtMetrics,
  mvtPages,
  mvtAudiences,
  mvtCustomCode,
  mvtTrafficAllocation,
  mvtSchedule,
  mvtApiNames,
  mvtHistory,
  mvtSettings,
  mvtOverview,
} from 'optly/services/url_helper';

import * as SectionModuleEnums from './enums';

const canUpdateLayer = [
  CurrentProjectGetters.project,
  PermissionsModuleFns.canUpdateLayer,
];

const showEditor = [EditorGetters.activeFrameId, id => !!id];

/**
 * Getter for the map of sidebar items necessary for this section.
 * @type {Getter}
 */
export const sideBarItems = [
  CurrentProjectGetters.id,
  CurrentLayerGetters.currentSingleExperimentFromAllExperimentsPointingToLayer,
  (
    projectId,
    experiment,
    isManagerHistoryTabFeatureEnabled,
    isWebMvtManagerVariableEnabled,
  ) => {
    const experimentId = experiment && experiment.get('id');
    return {
      variations: {
        name: SectionModuleEnums.Tabs.VARIATIONS,
        url: mvtSectionsDashboard(projectId, experimentId),
      },
      pages: {
        name: SectionModuleEnums.Tabs.PAGES,
        url: mvtPages(projectId, experimentId),
      },
      audiences: {
        name: SectionModuleEnums.Tabs.AUDIENCES,
        url: mvtAudiences(projectId, experimentId),
      },
      integrations: {
        name: SectionModuleEnums.Tabs.INTEGRATIONS,
        url: mvtIntegrations(projectId, experimentId),
      },
      metrics: {
        name: SectionModuleEnums.Tabs.METRICS,
        url: mvtMetrics(projectId, experimentId),
      },
      custom_code: {
        name: SectionModuleEnums.Tabs.CUSTOM_CODE,
        url: mvtCustomCode(projectId, experimentId),
      },
      traffic_allocation: {
        name: SectionModuleEnums.Tabs.TRAFFIC_ALLOCATION,
        url: mvtTrafficAllocation(projectId, experimentId),
      },
      schedule: {
        name: SectionModuleEnums.Tabs.SCHEDULE,
        url: mvtSchedule(projectId, experimentId),
      },
      api_names: {
        name: SectionModuleEnums.Tabs.API_NAMES,
        url: mvtApiNames(projectId, experimentId),
      },
      history: {
        name: SectionModuleEnums.Tabs.HISTORY,
        url: mvtHistory(projectId, experimentId),
      },
      settings: {
        name: SectionModuleEnums.Tabs.SETTINGS,
        url: mvtSettings(projectId, experimentId),
      },
      overview: {
        name: SectionModuleEnums.Tabs.OVERVIEW,
        url: mvtOverview(projectId, experimentId),
      },
    };
  },
];

/**
 * This is used to preload the iframes in the background when the user first loads the Variations page.
 */
const orderedVariations = [
  CurrentLayerGetters.allSectionsPointingToLayer,
  sections => {
    if (sections) {
      return sections.flatMap(section => section.get('variations'));
    }
    return toImmutable([]);
  },
];

/**
 * LayerExperiment entity within the Layer with policy 'multivariate'.
 * In other words, this is the destination layer experiment containing the
 * combinations.
 */
const destinationLayerExperiment = [
  CurrentLayerGetters.id,
  LayerExperiment.getters.entityCache,
  (currentLayerId, experimentsCache) => {
    if (!_.isNumber(currentLayerId)) {
      return null;
    }
    return (
      experimentsCache.find(
        experiment => experiment.get('layer_id') === currentLayerId,
      ) || null
    );
  },
];

/**
 * Destination LayerExperiment ID
 */
const destinationLayerExperimentId = [
  destinationLayerExperiment,
  layerExperiment => {
    if (layerExperiment) {
      return layerExperiment.get('id');
    }
    return null;
  },
];

/**
 * Returns variations with data needed by the Combinations tab.
 */
const mvtCombinations = [
  CurrentLayerGetters.layer,
  destinationLayerExperiment,
  CurrentLayerGetters.liveCommit,
  (currentLayer, experiment, liveCommit) => {
    if (!experiment) {
      return toImmutable([]);
    }
    let combinations = LayerExperiment.fns.addPercentageToVariations(
      experiment.get('variations'),
    );
    const liveCopyExperiment =
      (liveCommit &&
        liveCommit
          .get('revisions')
          .get('layer_experiment')
          .get(0)) ||
      null;
    combinations = CurrentLayerFns.addStatusInfoToVariations(
      currentLayer,
      combinations,
      experiment,
      liveCopyExperiment,
    );
    return combinations;
  },
];

/**
 * Returns variationWrappers with data needed by the traffic allocation tab for partial factorial.
 */
const mvtCombinationsFormatted = [
  CurrentLayerGetters.layer,
  destinationLayerExperiment,
  canUpdateLayer,
  CurrentLayerGetters.liveCommit,
  (currentLayer, experiment, canUpdateLayer, liveCommit) => {
    if (!experiment) {
      return toImmutable([]);
    }
    const liveCopyExperiment =
      (liveCommit &&
        liveCommit
          .get('revisions')
          .get('layer_experiment')
          .get(0)) ||
      null;
    const liveCopyExperiments =
      (liveCopyExperiment &&
        liveCopyExperiment.size &&
        toImmutable([liveCopyExperiment])) ||
      toImmutable([]);
    if (!liveCopyExperiments.size) {
      // If the experiment has not started yet, then we will give customers the ability to stop partial factorial
      // combinations before the experiment has started.
      return LayerExperiment.fns.formatTrafficAllocationVariationsForUnstartedPartialFactorial(
        experiment.get('variations'),
        canUpdateLayer,
        experiment.get('id'),
      );
    }
    return LayerExperiment.fns.formatTrafficAllocationVariations(
      experiment.get('variations'),
      canUpdateLayer,
      experiment.get('id'),
      liveCopyExperiments,
      true,
    );
  },
];

/**
 * Returns the number of combinations
 */
const mvtCombinationCount = [
  mvtCombinations,
  combinations => combinations.size,
];

/**
 * Returns the active sections with data needed by the MVT sections dashboard, including modification info
 */
const mvtSections = [
  CurrentLayerGetters.layer,
  CurrentLayerGetters.id,
  canUpdateLayer,
  ExperimentSectionGetters.entityCache,
  CurrentLayerGetters.liveCommit,
  (currentLayer, currentLayerId, canUpdateLayer, sectionsCache, liveCommit) => {
    const sectionsInLayer = sectionsCache.filter(
      section =>
        section.get('layer_id') === currentLayerId &&
        section.get('archived') === false,
    );

    if (!sectionsInLayer) {
      return toImmutable([]);
    }
    return sectionsInLayer
      .toList()
      .sortBy(section => section.get('id'))
      .map(section => {
        const liveCopySection =
          (liveCommit &&
            liveCommit
              .get('revisions')
              .get('experiment_section')
              .find(
                expSection => expSection.get('id') === section.get('id'),
              )) ||
          null;
        let variations = LayerExperiment.fns.addPercentageToVariations(
          section.get('variations'),
        );
        variations = CurrentLayerFns.addStatusInfoToVariations(
          currentLayer,
          variations,
          section,
          liveCopySection,
        );
        return section.set('variations', variations);
      });
  },
];

/**
 * Grabs the MVT experiment and checks if the experiment lives in the live commit, returns a boolean
 */
const isMVTExperimentLive = [
  CurrentLayerGetters.allExperimentsPointingToLayer,
  CurrentLayerGetters.liveCommitLayerExperiments,
  (experimentList, liveExperimentList) => {
    const experimentId = experimentList.first().get('id');
    return LayerExperiment.fns.isExperimentLive(
      experimentId,
      liveExperimentList,
    );
  },
];

/**
 * Grabs the traffic policy for the MVT layer
 */
const multivariateTrafficPolicy = [
  destinationLayerExperiment,
  layerExperiment =>
    layerExperiment.get('multivariate_traffic_policy') ||
    LayerExperiment.enums.multivariateTrafficPolicies.FULL_FACTORIAL,
];

/**
 * Grabs the traffic allocation policy for the MVT layer
 */
const currentlySelectedTrafficAllocationPolicy = [
  destinationLayerExperiment,
  layerExperiment => layerExperiment.get('allocation_policy'),
];

export default {
  canUpdateLayer,
  currentlySelectedTrafficAllocationPolicy,
  destinationLayerExperiment,
  destinationLayerExperimentId,
  isMVTExperimentLive,
  multivariateTrafficPolicy,
  mvtCombinations,
  mvtCombinationsFormatted,
  mvtCombinationCount,
  mvtSections,
  orderedVariations,
  showEditor,
  sideBarItems,
};
