import PropTypes from 'prop-types';
import React from 'react';

import { feature } from '@optimizely/js-sdk-lab/src/decorators';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import { connect } from 'core/ui/decorators';

import DashboardHeader from 'react_components/dashboard_header';

import Immutable from 'optly/immutable';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import ProjectFns from 'optly/modules/entity/project/fns';
import {
  canUseCrossProjectMetrics,
  canUseEventInspector,
  canUsePlugins,
  canUseRecommendationsDashboard,
} from 'optly/modules/permissions/getters';
import { Features } from 'optly/utils/enums';

import UrlHelper from 'optly/services/url_helper';
import NavConstants from 'optly/services/navigation';

@connect({
  canUseCrossProjectMetrics,
  canUseEventInspector,
  canUseRecommendationsDashboard,
  currentProject: CurrentProjectGetters.project,
  currentProjectId: CurrentProjectGetters.id,
  canUsePlugins,
  isCustomProject: CurrentProjectGetters.isCustomProject,
  isFullStackProject: CurrentProjectGetters.isFullStackProject,
  primarySDKLanguage: CurrentProjectGetters.primarySDKLanguage,
})
@feature('metrics_usability')
@feature('metrics_hub')
class DataLayerTopbar extends React.Component {
  static displayName = 'DataLayerTopbar';

  static propTypes = {
    activeTab: PropTypes.oneOf(Object.values(NavConstants.DataLayerTabs)),
    canUseCrossProjectMetrics: PropTypes.bool,
    canUseEventInspector: PropTypes.bool,
    canUsePlugins: PropTypes.bool,
    canUseRecommendationsDashboard: PropTypes.bool,
    currentProject: PropTypes.instanceOf(Immutable.Map),
    currentProjectId: PropTypes.number,
    isCustomProject: PropTypes.bool,
    primarySDKLanguage: PropTypes.string,
  };

  render() {
    const {
      activeTab,
      canUseCrossProjectMetrics,
      canUseEventInspector,
      canUsePlugins,
      canUseRecommendationsDashboard,
      currentProject,
      currentProjectId,
      isCustomProject,
      isFullStackProject,
      primarySDKLanguage,
    } = this.props;

    const isTemplatesEnabled =
      isFeatureEnabled(Features.USE_OPTIMIZELY_TEMPLATES) ||
      isFeatureEnabled(Features.USE_MERGED_TEMPLATES);

    const tabs = [
      {
        id: NavConstants.DataLayerTabs.VIEW_TAB,
        isVisible: !isCustomProject,
        testSection: 'views-tab',
        title: 'Pages',
        url: UrlHelper.pagesHome(currentProjectId),
      },
      {
        id: NavConstants.DataLayerTabs.EVENT_TAB,
        isVisible: !this.metrics_hub, // tab is migrated to Metrics Hub section if the feature flag is on
        testSection: 'events-tab',
        title: 'Events',
        url: UrlHelper.eventsHome(currentProjectId),
      },
      // TODO: Remove metrics_usability flag once feature is rolled out 100%
      {
        id: NavConstants.DataLayerTabs.METRIC_TAB,
        isVisible:
          canUseCrossProjectMetrics &&
          !isFullStackProject &&
          this.metrics_usability,
        testSection: 'metrics-tab',
        title: 'Metrics',
        url: UrlHelper.metricsHome(currentProjectId),
      },
      {
        id: NavConstants.DataLayerTabs.INSPECTOR_TAB,
        isVisible: canUseEventInspector,
        testSection: 'inspector-tab',
        title: 'Inspector',
        url: UrlHelper.eventInspector(currentProjectId),
      },
      {
        id: NavConstants.DataLayerTabs.PLUGIN_TAB,
        isVisible: canUsePlugins && !isCustomProject,
        testSection: 'plugins-tab',
        title: 'Templates',
        url: UrlHelper.extensionsHome(currentProjectId),
      },
      {
        id: NavConstants.DataLayerTabs.CATALOGS_DASHBOARD_TAB,
        isVisible: !!canUseRecommendationsDashboard,
        testSection: 'catalogs-dashboard-tab',
        title: 'Catalogs',
        url: UrlHelper.catalogDashboard(currentProjectId),
      },
      {
        id: NavConstants.DataLayerTabs.RECOMMENDERS_DASHBOARD_TAB,
        isVisible: !!canUseRecommendationsDashboard,
        testSection: 'recommenders-dashboard-tab',
        title: 'Recommenders',
        url: UrlHelper.recommenderDashboard(currentProjectId),
      },
    ];

    const topBarTitle = isCustomProject ? 'Events' : 'Implementation';

    return (
      <DashboardHeader
        activeTab={activeTab}
        projectName={ProjectFns.formatAnyProjectName(
          currentProject,
          primarySDKLanguage,
        )}
        tabs={tabs}
        testSection="data-layer-topbar-root"
        title={topBarTitle}
      />
    );
  }
}

export default DataLayerTopbar;
