import React from 'react';
import PropTypes from 'prop-types';

import { Poptip, Icon } from 'optimizely-oui';

import Immutable from 'optly/immutable';
import {
  Sidebar,
  SidebarNavListBody,
  SidebarNavList,
  SidebarNavListItem,
} from 'react_components/sidebar';

import { connect } from 'core/ui/decorators';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import * as LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import PermissionsGetters from 'optly/modules/permissions/getters';
import { isExperimentOverviewEnabled } from 'optly/utils/features';

import {
  enums as CampaignManagerEnums,
  fns as CampaignManagerFns,
} from 'bundles/p13n/modules/campaign_manager';

import Header from './header';

const metricsPoptip = `You need to add at least one metric to start this campaign.
Metrics will generate campaign results and help you measure performance.`;

/**
 * A parameterizable Sidebar component for the WEB Campaign Managers (AB/MVT/P13N).
 * @param {String} componentId
 * @param {String} campaignType
 * @returns {React.Component<NavSidebarWeb>}
 */
export default function(componentId, campaignType) {
  @connect({
    currentProject: CurrentProjectGetters.project,
    currentProjectId: CurrentProjectGetters.id,
    currentLayer: CurrentLayerGetters.layer,
    currentLayerId: CurrentLayerGetters.id,

    /* ===========================================================
     * PUBLISH STATUS INDICATORS
     * =========================================================== */
    // Variations Tab
    draftChangesFromAllExperimentsOrSections:
      CurrentLayerGetters.draftChangesFromAllExperimentsOrSections,
    currentCampaignLiveChanges: CurrentLayerGetters.currentCampaignLiveChangesByExperimentStatus(
      LayerExperimentEnums.status.ACTIVE,
    ),
    draftTooltipText: [
      CurrentLayerGetters.newChangesFromAllExperiments,
      CurrentLayerGetters.modifiedChangesFromAllExperiments,
      CurrentLayerGetters.deletedChangesFromAllExperiments,
      (n, m, d) => CampaignManagerFns.draftTooltipText(n.size, m.size, d.size),
    ],

    // Pages Tab
    addedViews: CurrentLayerGetters.addedViews,
    removedViews: CurrentLayerGetters.removedViews,

    // Audiences Tab
    addedAudiences:
      CurrentLayerGetters.addedAudiencesFromAllExperimentsPointingToLayer,
    removedAudiences:
      CurrentLayerGetters.removedAudiencesFromAllExperimentsPointingToLayer,
    hasChangedAudienceConditions:
      CurrentLayerGetters.hasChangedAudienceConditions,

    // Integrations Tab
    haveIntegrationSettingsChanged:
      CurrentLayerGetters.haveIntegrationSettingsChanged,

    // Shared Code Tab
    campaignCustomCodeLinesChanged:
      CurrentLayerGetters.campaignCustomCodeLinesChanged,

    // Traffic Allocation Tab
    hasTrafficAllocationToPublish:
      CurrentLayerGetters.hasTrafficAllocationToPublish,
    hasDraftHoldbackSetting: CurrentLayerGetters.hasDraftHoldbackSetting,
    customCodeTooltipText: CurrentLayerGetters.customCodeTooltipText,

    hasLayerMetrics: CurrentLayerGetters.hasLayerMetrics,
    canUseUrlTargeting: PermissionsGetters.canUseUrlTargeting,
    hasDirtySettingsToPublish: CurrentLayerGetters.hasDirtySettingsToPublish,
  })
  class NavSidebarWeb extends React.Component {
    static componentId = `${componentId}-sidebar`;

    static propTypes = {
      /* A list of optional sidebar rows to render. Available keys are:
       * {
       *   overview,
       *   experiences,
       *   variations,
       *   pages,
       *   audiences,
       *   integrations,
       *   metrics,
       *   custom_code,
       *   traffic_allocation,
       *   schedule,
       *   api_names,
       *   history,
       *   settings
       * }
       */
      items: PropTypes.object.isRequired,

      /* Whether to show the plain CHANGED badge instead of draft/live counts for Variations (default false). */
      showVariationChangedBadge: PropTypes.bool,

      /* The name to display for this sidebar. */
      name: PropTypes.string.isRequired,

      activeTab: PropTypes.oneOf(Object.keys(CampaignManagerEnums.tabs))
        .isRequired,
      // Variations Tab
      draftChangesFromAllExperimentsOrSections: PropTypes.instanceOf(
        Immutable.List,
      ),
      currentCampaignLiveChanges: PropTypes.instanceOf(Immutable.List),
      draftTooltipText: PropTypes.string.isRequired,
      // Pages Tab
      addedViews: PropTypes.instanceOf(Immutable.List),
      removedViews: PropTypes.instanceOf(Immutable.List),
      // Audiences Tab
      addedAudiences: PropTypes.instanceOf(Immutable.List),
      removedAudiences: PropTypes.instanceOf(Immutable.List),
      hasChangedAudienceConditions: PropTypes.bool,
      // Integrations Tab
      haveIntegrationSettingsChanged: PropTypes.bool,
      // Shared Code Tab
      campaignCustomCodeLinesChanged: PropTypes.number,
      // Traffic Allocation Tab
      hasDraftHoldbackSetting: PropTypes.bool,
      hasTrafficAllocationToPublish: PropTypes.bool,
      customCodeTooltipText: PropTypes.string,
      hasLayerMetrics: PropTypes.number,
      canUseUrlTargeting: PropTypes.bool,
      hasDirtySettingsToPublish: PropTypes.bool,
      experiencesHaveUnpublishedChanges: PropTypes.bool,
    };

    static defaultProps = {
      showVariationChangedBadge: false,
      experiencesHaveUnpublishedChanges: false,
    };

    renderVariationChangeCounts() {
      const {
        draftChangesFromAllExperimentsOrSections,
        currentCampaignLiveChanges,
        draftTooltipText,
      } = this.props;

      return (
        <ul className="lego-badge">
          <li
            className={
              draftChangesFromAllExperimentsOrSections.size && 'badge__draft'
            }
            data-test-section={`${componentId}-variations-draft-line-count`}>
            <Poptip content={draftTooltipText}>
              <p>{draftChangesFromAllExperimentsOrSections.size}</p>
            </Poptip>
          </li>

          <li
            className={currentCampaignLiveChanges.size && 'badge__live'}
            data-test-section={`${componentId}-variations-live-line-count`}>
            <Poptip content={`${currentCampaignLiveChanges.size} Live`}>
              <p>{currentCampaignLiveChanges.size}</p>
            </Poptip>
          </li>
        </ul>
      );
    }

    renderVariationChangedBadge() {
      return (
        <ul className="lego-badge">
          {Boolean(
            this.props.draftChangesFromAllExperimentsOrSections.size,
          ) && (
            <li
              className="badge__draft"
              data-test-section={`${componentId}-variations-dirty-status`}>
              Changed
            </li>
          )}
        </ul>
      );
    }

    render() {
      const {
        activeTab,
        canUseUrlTargeting,
        items,
        name,

        /* ===========================================================
         * PUBLISH STATUS INDICATORS
         * ===========================================================
         */
        // Variations Tab
        showVariationChangedBadge,
        // Pages Tab
        addedViews,
        removedViews,
        // Audiences Tab
        addedAudiences,
        removedAudiences,
        hasReorderedAudiences,
        hasChangedAudienceConditions,
        // Integratiosn Tab
        haveIntegrationSettingsChanged,
        // Shared Code Tab
        campaignCustomCodeLinesChanged,
        // Has draft holdback setting
        hasTrafficAllocationToPublish,
        hasDraftHoldbackSetting,
        customCodeTooltipText,
        hasLayerMetrics,
        experiencesHaveUnpublishedChanges,
        hasDirtySettingsToPublish,
        currentLayerId,
        currentLayer,
      } = this.props;

      return (
        <Sidebar testSection={`${componentId}-sidebar`}>
          <Header campaignType={campaignType} layerId={currentLayerId} />
          <SidebarNavListBody>
            <SidebarNavList
              label={`Manage ${name}`}
              testSection={`${componentId}-nav-list`}>
              {items.overview && isExperimentOverviewEnabled() && (
                <SidebarNavListItem
                  isActive={activeTab === items.overview.name}
                  testSection={`${componentId}-nav-item-overview`}
                  href={items.overview.url}>
                  <div className="flex flex-align--center">
                    <div>Overview</div>
                  </div>
                </SidebarNavListItem>
              )}

              {items.experiences && (
                <SidebarNavListItem
                  isActive={activeTab === items.experiences.name}
                  testSection={`${componentId}-nav-item-experiences`}
                  href={items.experiences.url}>
                  <div className="flex flex-align--center">
                    <div>Experiences</div>
                    {experiencesHaveUnpublishedChanges && (
                      <div className="anchor--right flex--none soft--left">
                        <ul className="lego-badge">
                          <li className="badge__draft">Unpublished</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </SidebarNavListItem>
              )}

              {items.variations && (
                <SidebarNavListItem
                  isActive={activeTab === items.variations.name}
                  testSection={`${componentId}-nav-item-variations`}
                  href={items.variations.url}>
                  <div className="flex flex-align--center">
                    <div>Variations</div>
                    <div className="anchor--right flex--none soft--left">
                      {showVariationChangedBadge
                        ? this.renderVariationChangedBadge()
                        : this.renderVariationChangeCounts()}
                    </div>
                  </div>
                </SidebarNavListItem>
              )}

              {items.pages && (
                <SidebarNavListItem
                  isActive={activeTab === items.pages.name}
                  testSection={`${componentId}-nav-item-pages`}
                  href={items.pages.url}>
                  <div className="flex flex-align--center">
                    {!canUseUrlTargeting && <div>Pages</div>}
                    {canUseUrlTargeting && <div>Targeting</div>}
                    <div className="anchor--right flex--none soft--left">
                      <ul className="lego-badge">
                        {Boolean(addedViews.size || removedViews.size) && (
                          <li
                            className="badge__draft"
                            data-test-section={`${componentId}-pages-dirty-status`}>
                            Changed
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </SidebarNavListItem>
              )}

              {items.audiences && (
                <SidebarNavListItem
                  isActive={activeTab === items.audiences.name}
                  testSection={`${componentId}-nav-item-audiences`}
                  href={items.audiences.url}>
                  <div className="flex flex-align--center">
                    <div>Audiences</div>
                    <div className="anchor--right flex--none soft--left">
                      <ul className="lego-badge">
                        {Boolean(
                          addedAudiences.size ||
                            removedAudiences.size ||
                            hasChangedAudienceConditions,
                        ) && (
                          <li
                            className="badge__draft"
                            data-test-section={`${componentId}-audiences-dirty-status`}>
                            Changed
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </SidebarNavListItem>
              )}

              {items.integrations && (
                <SidebarNavListItem
                  isActive={activeTab === items.integrations.name}
                  testSection={`${componentId}-nav-item-integrations`}
                  href={items.integrations.url}>
                  <div className="flex flex-align--center">
                    <div>Integrations</div>
                    <div className="anchor--right flex--none soft--left">
                      <ul className="lego-badge">
                        {haveIntegrationSettingsChanged && (
                          <li className="badge__draft">Changed</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </SidebarNavListItem>
              )}

              {items.metrics && (
                <SidebarNavListItem
                  isActive={activeTab === items.metrics.name}
                  testSection={`${componentId}-nav-item-metrics`}
                  href={items.metrics.url}>
                  <div className="flex flex-align--center">
                    <div>Metrics</div>
                    {!hasLayerMetrics && (
                      <div className="anchor--right flex--none soft--left">
                        <Poptip content={metricsPoptip}>
                          <Icon
                            name="circle-exclamation"
                            size="small"
                            className="lego-icon color--brand vertical-align--middle cursor--pointer push-half--left flex--none"
                          />
                        </Poptip>
                      </div>
                    )}
                  </div>
                </SidebarNavListItem>
              )}

              {items.custom_code && (
                <SidebarNavListItem
                  isActive={activeTab === items.custom_code.name}
                  testSection={`${componentId}-nav-item-custom-code`}
                  href={items.custom_code.url}>
                  <div className="flex flex-align--center">
                    <div>Shared Code</div>
                    <div className="anchor--right flex--none soft--left">
                      <ul className="lego-badge">
                        {Boolean(campaignCustomCodeLinesChanged) && (
                          <li
                            className="badge__draft"
                            data-test-section={`${componentId}-custom-code-draft-line-count`}>
                            {/* dangerouslySetInnerHTML is necessary to show the <br> breaks in the tooltip text */}
                            <Poptip
                              content={React.createElement('div', {
                                dangerouslySetInnerHTML: {
                                  __html: customCodeTooltipText,
                                },
                              })}>
                              <p>{campaignCustomCodeLinesChanged}</p>
                            </Poptip>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </SidebarNavListItem>
              )}

              {items.traffic_allocation && (
                <SidebarNavListItem
                  isActive={activeTab === items.traffic_allocation.name}
                  testSection={`${componentId}-nav-item-traffic-allocation`}
                  href={items.traffic_allocation.url}>
                  <div className="flex flex-align--center">
                    <div>Traffic Allocation</div>
                    <div className="anchor--right flex--none soft--left">
                      <ul className="lego-badge">
                        {(hasTrafficAllocationToPublish ||
                          hasDraftHoldbackSetting) && (
                          <li
                            className="badge__draft"
                            data-test-section={`${componentId}-nav-item-changed-badge`}>
                            Changed
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </SidebarNavListItem>
              )}

              {items.schedule && (
                <SidebarNavListItem
                  isActive={activeTab === items.schedule.name}
                  testSection={`${componentId}-nav-item-schedule`}
                  href={items.schedule.url}>
                  <div className="flex flex-align--center">
                    <div>Schedule</div>
                  </div>
                </SidebarNavListItem>
              )}

              {items.api_names && (
                <SidebarNavListItem
                  isActive={activeTab === items.api_names.name}
                  testSection={`${componentId}-nav-item-api-names`}
                  href={items.api_names.url}>
                  <div className="flex flex-align--center">
                    <div>API Names</div>
                  </div>
                </SidebarNavListItem>
              )}

              {items.history && (
                <SidebarNavListItem
                  isActive={activeTab === items.history.name}
                  testSection={`${componentId}-nav-item-history`}
                  href={items.history.url}>
                  <div className="flex flex-align--center">
                    <div>History</div>
                  </div>
                </SidebarNavListItem>
              )}

              {items.settings && (
                <SidebarNavListItem
                  isActive={activeTab === items.settings.name}
                  testSection={`${componentId}-nav-item-settings`}
                  href={items.settings.url}>
                  <div className="flex flex-align--center">
                    <div>Settings</div>
                    <div className="anchor--right flex--none soft--left">
                      <ul className="lego-badge">
                        {hasDirtySettingsToPublish && (
                          <li
                            className="badge__draft"
                            data-test-section={`${componentId}-settings-dirty-status`}>
                            Changed
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </SidebarNavListItem>
              )}
            </SidebarNavList>
          </SidebarNavListBody>
        </Sidebar>
      );
    }
  }
  return NavSidebarWeb;
}
