import PropTypes from 'prop-types';
import React from 'react';
import htmlSanitizer from 'sanitizer';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import { Attention, Button, Link, Sheet } from 'optimizely-oui';

import Immutable from 'optly/immutable';

import { connect } from 'core/ui/decorators';
import Router from 'core/router';
import ui from 'core/ui';

import { actions as SentryActions } from 'optly/modules/sentry';
import UrlHelper from 'optly/services/url_helper';
import sanitizeHTML from 'optly/utils/sanitize_html';

import {
  actions as CurrentlyEditingExperimentActions,
  getters as CurrentlyEditingExperimentGetters,
  enums as CurrentlyEditingExperimentEnums,
} from 'bundles/p13n/modules/currently_editing_experiment';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import {
  actions as LayerActions,
  constants as LayerConstants,
  enums as LayerEnums,
} from 'optly/modules/entity/layer';
import { getters as LayerSettingsGetters } from 'bundles/p13n/modules/layer_settings';
import { fns as PermissionsFns } from 'optly/modules/permissions';
import TrafficAllocationPolicyConstants from 'bundles/p13n/components/traffic_allocation/traffic_allocation_policy/constants';
import { actions as ViewActions } from 'optly/modules/entity/view';

import LoadingOverlay from 'react_components/loading_overlay';
import LayerSettings from 'bundles/p13n/components/layer_settings';

import Targeting from '../ab_mvt_targeting';

const LOADING_ID = 'mab-layer-detail';

@withTrack
@connect({
  canUpdateLayer: [
    CurrentProjectGetters.project,
    PermissionsFns.canUpdateLayer,
  ],
  currentProjectId: CurrentProjectGetters.id,
  errors: CurrentlyEditingExperimentGetters.errors,
  experimentToSave: CurrentlyEditingExperimentGetters.experimentToSave,
  layerDataToSave: CurrentlyEditingExperimentGetters.layerDataToSave(
    LayerEnums.policy.SINGLE_EXPERIMENT,
  ),
  name: LayerSettingsGetters.name,
  targetingType: CurrentlyEditingExperimentGetters.targetingType(
    LayerEnums.policy.SINGLE_EXPERIMENT,
  ),
})
class MultiArmedBanditDialog extends React.Component {
  static propTypes = {
    canUpdateLayer: PropTypes.bool.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    errors: PropTypes.instanceOf(Immutable.Map).isRequired,
    experimentToSave: PropTypes.object.isRequired,
    layerDataToSave: PropTypes.object.isRequired,
    name: PropTypes.string,
    targetingType: PropTypes.string.isRequired,
    track: PropTypes.func.isRequired,
  };

  static defaultProps = {
    name: '',
  };

  state = {
    isSaving: false,
  };

  constructor(props) {
    super(props);

    CurrentlyEditingExperimentActions.init({
      mode: CurrentlyEditingExperimentEnums.modes.CREATE,
      isMultivariateTest: false,
    });

    CurrentlyEditingExperimentActions.updateCurrentlySelectedTrafficAllocationPolicy(
      TrafficAllocationPolicyConstants.TrafficAllocationPolicyTypes
        .MAXIMIZE_CONVERSIONS,
    );
  }

  clearNameError = () => {
    CurrentlyEditingExperimentActions.setErrors({
      name: '',
    });
  };

  fetchNewlyCreatedViewIfNecessaryAndCompleteSave = (policy, newLayer) => {
    const { currentProjectId, targetingType, track } = this.props;
    let fetchDeferred;

    if (targetingType === LayerConstants.TargetingTypes.URL) {
      fetchDeferred = ViewActions.fetch(newLayer.url_targeting.view_id);
    } else {
      fetchDeferred = $.Deferred().resolve();
    }
    fetchDeferred.then(() => {
      const url = UrlHelper.campaignHome(currentProjectId, newLayer.id);
      ui.hideDialog().then(() => {
        Router.go(url);
      });
    });
    track('MAB Creation Dialogue Create Clicked');
  };

  onCancelClick = () => {
    const { track } = this.props;

    ui.hideDialog();
    track('MAB Creation Dialogue Cancel Clicked');
  };

  saveMAB = () => {
    const { experimentToSave, layerDataToSave, name } = this.props;
    const sanitizedName = htmlSanitizer.escape(name);

    const validationErrorsMap = CurrentlyEditingExperimentActions.validateFormDataOnSave(
      LayerEnums.policy.SINGLE_EXPERIMENT,
    );

    const errorMessages = Object.values(validationErrorsMap);
    if (errorMessages.some(error => !!error)) {
      errorMessages.forEach(message => {
        if (message) {
          ui.showNotification({
            message,
            type: 'error',
          });
        }
      });

      return;
    }

    this.setState({
      isSaving: true,
    });

    try {
      const createMABLayerDeferred = LayerActions.createSingleABTestLayer(
        layerDataToSave,
        experimentToSave,
      );
      createMABLayerDeferred.then(newLayer => {
        const { track } = this.props;
        const segmentParams = {
          type: newLayer.type,
          experimentId: newLayer.id,
          experimentName: newLayer.name,
        };
        ui.showNotification({
          message: tr(
            'The multi-armed bandit <b>{0}</b> has been created.',
            sanitizedName,
          ),
        });
        this.fetchNewlyCreatedViewIfNecessaryAndCompleteSave(
          LayerEnums.policy.SINGLE_EXPERIMENT,
          newLayer,
        );
        this.setState({
          isSaving: false,
        });
        track('Experiment Created', segmentParams);
      });

      ui.loadingWhen(LOADING_ID, createMABLayerDeferred);
    } catch (e) {
      ui.showNotification({
        type: 'error',
        message:
          'There was an error creating a new Multi-Armed Bandit. Please refresh the page and try again.',
      });

      SentryActions.withScope(scope => {
        scope.setExtra('layerData', layerDataToSave);
        SentryActions.captureException(e);
      });
      this.setState({
        isSaving: false,
      });
    }
  };

  componentWillUnmount() {
    CurrentlyEditingExperimentActions.updateCurrentlySelectedTrafficAllocationPolicy(
      {
        policy:
          TrafficAllocationPolicyConstants.TrafficAllocationPolicyTypes.MANUAL,
      },
    );
  }

  render() {
    const { canUpdateLayer, errors } = this.props;

    const { isSaving } = this.state;

    return (
      <Sheet
        hasRequiredFieldsIndicator={true}
        footerButtonList={[
          <Button
            key="cancel"
            onClick={this.onCancelClick}
            style="plain"
            testSection="cancel-button">
            Cancel
          </Button>,
          <Button
            isDisabled={!canUpdateLayer || isSaving}
            key="create"
            onClick={this.saveMAB}
            style="highlight"
            testSection="save-bandit-button">
            Create Bandit
          </Button>,
        ]}
        onClose={ui.hideDialog}
        subtitle={
          <React.Fragment>
            <span>
              <span>
                Create a multi-armed bandit optimization to maximize your
                conversions or revenue.{' '}
              </span>
              <Link
                href="https://support.optimizely.com/hc/en-us/articles/4410289035405-Maximize-lift-with-multi-armed-bandit-optimizations"
                newWindow={true}>
                Learn More
              </Link>
            </span>
            <div className="push--top width--1-1">
              <Attention alignment="left" type="brand">
                This type of optimization will not include statistical
                significance calculations.
              </Attention>
            </div>
          </React.Fragment>
        }
        title="New Multi-Armed Bandit">
        <LoadingOverlay loadingId={LOADING_ID}>
          <div className="push-quad--bottom">
            <LayerSettings
              campaignType={LayerEnums.campaignTypes.MULTIARMED_BANDIT}
              nameError={errors.get('name')}
              onNameChange={this.clearNameError}
            />
            <Targeting
              campaignType={LayerEnums.campaignTypes.MULTIARMED_BANDIT}
            />
          </div>
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export default MultiArmedBanditDialog;
