import keyMirror from 'optly/utils/key_mirror';

export const Tabs = keyMirror({
  VARIATIONS: null,
  PAGES: null,
  AUDIENCES: null,
  INTEGRATIONS: null,
  METRICS: null,
  CUSTOM_CODE: null,
  API_NAMES: null,
  SCHEDULE: null,
  TRAFFIC_ALLOCATION: null,
  HISTORY: null,
  SETTINGS: null,
  OVERVIEW: null,
});

/**
 * Tab choices for the mvt variations panel tab
 */
export const MvtTabs = keyMirror({
  SECTIONS: null,
  COMBINATIONS: null,
});

export default {
  Tabs,
  MvtTabs,
};
