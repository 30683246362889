/**
 * Utility functions centralized to check if the Feature Flag is enabled.
 */
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

export const isWinnerRolloutFeatureEnabled = () =>
  isFeatureEnabled('winner_rollout_feature');

export const isCMABGroupedExperienceEnabled = () =>
  isFeatureEnabled('web-cmab-prioritize-experiences');

export const isExperimentOverviewEnabled = () =>
  isFeatureEnabled('web_experiment_overview');

export default {
  isCMABGroupedExperienceEnabled,
  isWinnerRolloutFeatureEnabled,
};
