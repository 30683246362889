/**
 * This sidebar component is used by sidebars with the following componentIds:
 * p13n-widget-selector-sidebar, p13n-change-editor-sidebar, p13n-insert-html-sidebar,
 * p13n-insert-image-sidebar, p13n-widget-config-sidebar, and p13n-redirect-editor-sidebar.
 *
 * It basically consists only of:
 * 1) a small label indicating which project the entity is in,
 * 2) a back button breadcrumb (e.g. "< Create")
 * 3) a title
 * 4) a Save button
 */
import _ from 'lodash';

import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import flux from 'core/flux';
import mixins from 'bundles/p13n/components/layers/mixins';
import ui from 'core/ui';
import vueHelpersUtil from 'optly/utils/vue_helpers';
import { toImmutable } from 'optly/immutable';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { getters as CurrentlyEditingPluginGetters } from 'bundles/p13n/sections/plugin_builder/modules/currently_editing_plugin';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import {
  actions as EditorActions,
  getters as EditorGetters,
} from 'bundles/p13n/modules/editor';
import { fns as LayerFns } from 'optly/modules/entity/layer';
import { actions as EditorIframeActions } from 'bundles/p13n/modules/editor_iframe';
import SidebarHeaderMixin from 'bundles/p13n/modules/ui/mixins/sidebar_header';
import { fns as PermissionsModuleFns } from 'optly/modules/permissions';

import ConcurrentEditingContainer from 'bundles/p13n/components/concurrency/concurrent_editing_container';

import PluginsActions from 'optly/modules/entity/plugin/actions';

import Template from './template.html';

const SidebarHeader = {
  replace: true,

  template: Template,

  computed: {
    backButtonText() {
      const buttonText = this.isNewChange ? tr('Create') : tr('Changes');
      const backButtonTextValues = {
        'p13n-change-editor-sidebar': buttonText,
        'p13n-insert-html-sidebar': buttonText,
        'p13n-insert-image-sidebar': buttonText,
        'p13n-redirect-editor-sidebar': buttonText,
        'p13n-widget-config-sidebar': buttonText,
      };
      return backButtonTextValues[this.$parent.$options.componentId]
        ? backButtonTextValues[this.$parent.$options.componentId]
        : tr('Changes');
    },

    disableSaveButton() {
      return (
        !this.changeIsDirty ||
        (!this.canUpdateLayerExperiment &&
          !this.canUpdateLayerExperimentWithExtension) ||
        this.isEditorReadOnly ||
        (this.currentLayer &&
          LayerFns.isLayerReadonly(toImmutable(this.currentLayer)))
      );
    },

    isABTestLayer: mixins.isABTest,

    isPersonalizationLayer: mixins.isPersonalizationCampaign,

    sidebarTitle() {
      const sidebarTextValues = {
        'p13n-change-editor-sidebar': tr('Element Change'),
        'p13n-change-selector-sidebar': tr('Create Change'),
        'p13n-insert-html-sidebar': tr('Insert HTML'),
        'p13n-insert-image-sidebar': tr('Insert Image'),
        'p13n-redirect-editor-sidebar': tr('Redirect'),
        'p13n-widget-config-sidebar': this.currentlyEditingPlugin
          ? this.currentlyEditingPlugin.config.name
          : '',
      };

      return sidebarTextValues[this.$parent.$options.componentId]
        ? sidebarTextValues[this.$parent.$options.componentId]
        : '';
    },

    getBrandBlueDark() {
      return brandBlueDark;
    },
  },

  methods: {
    // TODO(ricky): Waiting on a good way to dynamically generate these labels as part of CM-50
    // getVariationLabel(variation) {
    //   return 'A';
    // },

    handleBackButtonClick() {
      switch (this.$parent.$options.componentId) {
        case 'p13n-change-editor-sidebar':
        case 'p13n-insert-html-sidebar':
        case 'p13n-insert-image-sidebar':
        case 'p13n-redirect-editor-sidebar':
        case 'p13n-widget-config-sidebar':
          const activeFrameId = flux.evaluateToJS(EditorGetters.activeFrameId);

          const changes = flux.evaluateToJS(
            EditorGetters.currentlyEditingActionFormatted(true),
          );
          // This resets the state of the editor preview to the saved changes
          // Particularly important for removing a widget that has been added and then cancelled
          EditorIframeActions.applyChanges(activeFrameId, changes);

          this.showCorrectSidebar(this.changeListSidebarComponentConfig);
          break;
        default:
          this.showChangeListSidebar(this.changeListSidebarComponentConfig);
      }
    },

    handleSaveButtonClick() {
      switch (this.$parent.$options.componentId) {
        case 'p13n-widget-config-sidebar':
          this.saveWidgetChange();
          this.sendSegmentEvent(this.changeListSidebarComponentConfig);
          break;
        case 'p13n-redirect-editor-sidebar':
          this.saveChangeAndShowChangeListSidebarAndRefreshCurrentIFrame(
            this.changeListSidebarComponentConfig,
          );
          break;
        default:
          this.saveChangeAndShowChangeListSidebar(
            this.changeListSidebarComponentConfig,
          );
      }
    },
    sendSegmentEvent(config) {
      const currentVariationUrlSegment = document.URL.split('/').at(-1);
      if (currentVariationUrlSegment === undefined) return;
      const currentVariationId = Number(currentVariationUrlSegment);
      if (Number.isNaN(currentVariationId)) return;
      const widgetChange = config.data.currentLayerExperimentOrSection.variations
        .filter(c => c.variation_id === currentVariationId)[0]
        .actions.filter(a => a.changes.filter(c => c.type === 'widget'));
      if (widgetChange.length > 0) {
        const widgetId = widgetChange[0].changes.at(-1).widget_id;
        PluginsActions.notifyPluginsEvent([widgetId], 'Template Added');
      }
    },

    saveWidgetChange() {
      // TODO(jordan): config validation
      const editingChange = vueHelpersUtil.sanitizeFromVueSideEffects(
        this.editingChange,
      );
      ui.loadingWhen(
        'widget-editor-sidebar',
        EditorActions.saveWidgetChange(editingChange).then(() => {
          this.showChangeListSidebar(this.changeListSidebarComponentConfig);
        }),
      );
    },
  },

  created() {
    flux.bindVueValues(this, {
      canUpdateLayerExperiment: [
        CurrentProjectGetters.project,
        PermissionsModuleFns.canUpdateLayerExperiment,
      ],
      canUpdateLayerExperimentWithExtension: [
        CurrentProjectGetters.project,
        PermissionsModuleFns.canUpdateLayerExperimentWithExtension,
      ],
      changeListSidebarComponentConfig:
        EditorGetters.changeListSidebarComponentConfig,
      currentLayer: CurrentLayerGetters.layer,
      currentProjectName: CurrentProjectGetters.name,
      currentlyEditingChange: EditorGetters.currentlyEditingChange,
      currentlyEditingChangeIsDirty:
        EditorGetters.currentlyEditingChangeIsDirty,
      currentlyEditingPlugin: CurrentlyEditingPluginGetters.data,
      isEditorReadOnly: EditorGetters.isEditorReadOnly,
      experienceOrExperimentName:
        EditorGetters.currentExperimentOrSectionDisplayName,
      selectedVariationName: [
        EditorGetters.selectedVariationId,
        EditorGetters.variationsInCurrentLayerExperimentOrSection,
        (selectedVariationId, variations) => {
          const selectedVariation = variations.find(
            variation => variation.get('variation_id') === selectedVariationId,
          );
          return selectedVariation && selectedVariation.get('name')
            ? selectedVariation.get('name')
            : '';
        },
      ],
    });
  },

  ready() {
    ui.renderReactComponent(this, {
      component: ConcurrentEditingContainer,
      el: this.$$.concurrentEditingContainer,
      dataBindings: {
        entityId: EditorGetters.currentLayerExperimentOrSectionId,
      },
    });
  },
};

export default _.merge({}, SidebarHeaderMixin, SidebarHeader);
